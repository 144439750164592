import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Container, ModalImg } from './ImageModal.style';
import WhiteModal from './WhiteModal';

interface Props {
  ariaLabel: string;
  imageUrl?: string;
  show: boolean;
  onClose: () => void;
}

const ImageModal: FunctionComponent<Props> = (props) => {
  const closeRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (props.show && closeRef.current) {
      closeRef.current.focus();
    }
  }, [props.show]);

  return (
    <WhiteModal ariaLabel={props.ariaLabel} show={props.show} onClose={props.onClose} contentStyle={{ width: 'auto' }}>
      <Container data-testid="image-modal">
        <ModalImg src={props.imageUrl} />
      </Container>
    </WhiteModal>
  );
};

export default ImageModal;
