import { Property } from '../../types/property';

export interface PropertyTour {
  url: string;
  tourIndex?: number;
}

const getPropertyTour = (property: Property): PropertyTour | null => {
  if (property['360Tours']) {
    const index = property['360Tours']?.findIndex((tour) => tour.type !== 'video');

    if (index >= 0) {
      return {
        url: property['360Tours'][index].url,
        tourIndex: index,
      };
    }
  }

  return null;
};

export default getPropertyTour;
