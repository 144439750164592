import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../../../web-ui/src/layout/PageWrapper.style';

export const Container = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;

  @media (${laptopMediumMax}) {
    padding: 20px 15px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.primaryTranslucent};
`;

export const ItemRow = styled.a`
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin-right: 30px;

  p {
    font-size: 16px;
    color: ${(props) => props.theme.primary};
    text-decoration: underline;
    margin-left: 10px;
    transition: 300ms all;
  }

  &:hover p {
    color: ${(props) => props.theme.backgroundMid};
  }
`;
