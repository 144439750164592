import { mobileXXLargeMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';

export const ServerSideBox = styled.section<{ aspectRatio: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  opacity: 0;
  width: 100%;
  overflow: hidden;
  padding-top: ${(props) => props.aspectRatio * 100}%;

  img {
    position: absolute;
    top: 0;
  }
`;

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    height: auto !important;

    .swiper-wrapper {
      transform: none !important;
    }

    .swiper-wrapper .swiper-slide {
      display: none !important;
    }

    .swiper-wrapper .swiper-slide-active {
      display: block !important;

      img {
        width: 700px !important;
        height: auto !important;
      }
    }
  }
`;

export const ImageBox = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  height: 100%;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;

  img {
    width: 180px;
    height: 30%;
    max-height: 180px;
  }

  p {
    margin-top: 30px;
    font-size: 20px;
    color: ${(props) => props.theme.white};
    text-align: center;
  }

  @media (${mobileXXLargeMax}) {
    svg {
      width: 70px;
      height: 70px;
    }

    p {
      font-size: 16px;
      margin-top: 15px;
    }
  }
`;

export const ArrowButton = styled.button<{ side: 'right' | 'left'; sitemapTouch?: boolean }>`
  position: absolute;
  top: 0;
  width: 100px;
  z-index: 1;

  /**
    * Prevent arrow taking up full height on sitemap image so user can magnify that area
    * still center arrow on sitemap image when height
  */
  ${({ sitemapTouch }) =>
    sitemapTouch
      ? `
        transform: translate(0, -50%); 
        top: 50%; 
        width: 50px;
      `
      : `
        top: 0;
        width: 100px;
        height: 100%;
      `}

  ${(props) => props.side === 'right' && 'right: 0;'}
  ${(props) => props.side === 'left' && 'left: 0;'}
  
  & svg {
    filter: drop-shadow(0px 0px 6px rgb(0 0 0 / 0.5));
  }

  @media print {
    display: none;
  }
`;

export const CounterContainer = styled.div<{ center?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  ${(props) =>
    props.center &&
    `
      max-width: ${MAX_WIDTH}px;
      margin: 0 auto;
  `}

  @media print {
    display: none;
  }
`;

export const PhotoCounter = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
  padding: 4px 6px;
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  left: 30px;
  z-index: 10;

  @media (${tabletMax}) {
    left: 10px;
  }
`;

export const PanoramicIconBox = styled.div`
  position: absolute;
  right: 5px;
  bottom: 30px;
`;

export const NoImageBackground = styled.div`
  background-color: ${(props) => props.theme.backgroundLighter};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  height: 100%;
  align-self: center;

  img {
    margin-top: 30px;
    width: 300px;
    height: 200px;
  }

  & > p {
    color: ${(props) => props.theme.backgroundLight};
    margin-top: 30px;
    font-size: 22px;
  }
`;

export const Curtain = styled.div`
  display: none;
  position: absolute;
  z-index: 5;
  top: 0;
  width: 25px;
  height: 100%;

  @media (pointer: coarse) {
    display: block;
  }
`;
