import React from 'react';
import { HorizDividerText, HorizDivider } from './HorizontalDivider.style';

interface HorizontalDividerProps {
  text?: string;
  mb?: number;
  mt?: number;
  dividerColor?: string;
  color?: string;
}

const HorizontalDivider = (props: HorizontalDividerProps) => {
  return (
    <HorizDivider mb={props.mb} mt={props.mt}>
      {props.text ? (
        <HorizDividerText data-testid="horizontal-text" bg={props.dividerColor} color={props.color}>
          {props.text}
        </HorizDividerText>
      ) : null}
    </HorizDivider>
  );
};

export default HorizontalDivider;
