import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import React, { FunctionComponent, MouseEvent } from 'react';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { closeTooltip } from '../notifications/Tooltip';
import { InfoButton } from './InfoIcon.style';

interface IconProps {
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  color?: string;
  icon?: IconDefinition;
  fontSize?: number;
  testID?: string;
  className?: string;
}

const InfoIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <InfoButton
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={closeTooltip}
      data-testid={props.testID || 'info-icon'}
      className={props.className}
    >
      <FontAwesomeIcon
        icon={props.icon || faInfoCircle}
        color={props.color || 'white'}
        style={{ fontSize: props.fontSize || 14 }}
      />
    </InfoButton>
  );
};

export default InfoIcon;
