import { mobileXXLargeMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div<{ isFeaturedDevelopment: boolean }>`
  padding-bottom: 30px;

  ${({ isFeaturedDevelopment }) =>
    isFeaturedDevelopment &&
    `
    p a {
      color: inherit;
    }
  `}
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 60px 0 30px;

  @media (${tabletMax}) {
    flex-direction: column;
  }
`;

export const TextBox = styled.div`
  @media (${tabletMax}) {
    margin-bottom: 15px;
  }
`;

export const Table = styled.table`
  background: ${(props) => props.theme.background};
  width: 100%;
  border-collapse: collapse;

  @media (${mobileXXLargeMax}) {
    display: none;
  }

  th {
    text-align: left;
    border: 1px solid ${(props) => props.theme.keyline};
    padding: 10px;
    color: ${(props) => props.theme.textDark};
  }

  td {
    border: 1px solid ${(props) => props.theme.keyline};
    padding: 10px;
    color: ${(props) => props.theme.textMid};
  }

  tbody {
    tr:nth-child(2n + 1) {
      background: ${(props) => props.theme.backgroundLightest};
    }
  }
`;

export const MobileTable = styled.div`
  display: none;

  @media (${mobileXXLargeMax}) {
    display: block;
  }
`;

export const MobileBox = styled.div`
  background: ${(props) => props.theme.background};
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(props) => props.theme.keyline};
  border-bottom: none;

  h3,
  p,
  b {
    color: ${(props) => props.theme.textDark};
  }

  &:nth-child(2n + 1) {
    background: ${(props) => props.theme.backgroundLightest};
  }

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.keyline};
  }
`;
