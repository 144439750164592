import { laptopMediumMax, largeTabletMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { MAX_WIDTH } from '../../../../web-ui/src/layout/PageWrapper.style';

export const Container = styled.div`
  background-color: ${(props) => props.theme.backgroundLightest};
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 20px 30px;

  @media (${laptopMediumMax}) {
    padding: 20px 15px;
  }
`;

export const ItemRow = styled.div`
  display: flex;

  @media (${tabletMax}) {
    flex-direction: column;
  }
`;

export const Item = styled.button`
  display: flex;
  flex-direction: row;
  margin: 0 30px 0 0;
  align-items: center;

  p,
  .buttonText {
    color: ${(props) => props.theme.primary};
    text-decoration: underline;
    font-size: 14px;
    padding: 20px 0 20px 10px;
    transition: 300ms all;
  }

  &:hover p,
  &:hover .buttonText {
    color: ${(props) => props.theme.backgroundMid};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.primaryTranslucent};
`;

export const NotesWrapper = styled.div`
  width: 100%;
  max-width: 730px;
  margin-left: -30px;

  @media (${largeTabletMax}) {
    margin-left: -15px;
  }
`;
