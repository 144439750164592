import { tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div<{ color: string; hideOnMobile?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.color};
  padding: 5px 0;

  ${(props) =>
    props.hideOnMobile &&
    `
    @media (${tabletMax}) {
      display: none;
    }
  `}
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 60px;
`;
