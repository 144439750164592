import { Property } from '../../types/property';
import { GalleryItem } from './getGalleryImages';

const getPropertyFloorPlan = (property: Property, imageLocator: string): GalleryItem[] | undefined => {
  return property.images
    ?.filter((image) => image.imageType === 'FloorPlan')
    .map((image, index) => ({
      type: 'image',
      image: image.urls[imageLocator] || image.url,
      width: image.width,
      height: image.height,
      displayOrder: image.displayOrder,
      key: image.url,
      index,
    }));
};

export default getPropertyFloorPlan;
