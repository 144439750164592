import { largeTabletMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';
import { RowAlignCenter } from '../layout';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 60px 0;

  @media (${largeTabletMax}) {
    margin: 30px 0;
    flex-direction: column;
  }
`;

export const SummaryBox = styled.div`
  position: relative;
  padding-right: 20px;
  flex: 1;

  // Allows custom colour on favourite icon
  button {
    color: inherit;
  }

  @media (${largeTabletMax}) {
    padding: 0;
    width: 100%;
    margin-top: 15px;
  }
`;

export const KeyDetails = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;

  @media (${largeTabletMax}) {
    max-width: 400px;
  }
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  margin-bottom: 15px;
  align-items: center;

  svg {
    font-size: 36px;
  }
`;

export const AgentBox = styled.div<{ color?: string }>`
  background: ${(props) => props.theme.background};
  max-width: ${(props) => (props.color ? '640px' : '440px')};
  min-width: ${(props) => (props.color ? '520px' : '440px')};
  flex: 1;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;

  ${(props) =>
    props.color &&
    `
    border: 2px solid ${props.color};
  `}

  @media (${largeTabletMax}) {
    margin-top: 30px;
    min-width: auto;
    max-width: 100%;
    width: 100%;
  }
`;

export const AgentButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AgentBoxHeader = styled.div<{ color?: string }>`
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : 'transparent')};
  padding: ${(props) => (props.color ? '10px' : '20px 20px 0')};
`;

export const ShowPhoneNumber = styled.div`
  display: block;
  width: 100%;

  @media (${mobileXXLargeMax}) {
    display: none;
  }
`;

export const AgentBoxContent = styled.div`
  padding: 20px;
  display: flex;

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
  }
`;

export const AgentExtraInfo = styled.div<{ show: boolean }>`
  flex: 1;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid ${(props) => props.theme.keyline};

  @media (${mobileXXLargeMax}) {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 30px;
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`;

export const AgentListItem = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const IconBox = styled.div`
  min-width: 18px;
  margin: 2px 10px 0 0;
  display: flex;
  justify-content: center;
`;

export const ShowMoreDetails = styled.button<{ color: string; show?: boolean }>`
  width: 100%;
  padding: 15px 0;
  display: none;
  color: ${(props) => props.color};
  text-decoration: underline;
  border-top: 2px dashed ${(props) => props.theme.keyline};
  font-size: 14px;

  @media (${mobileXXLargeMax}) {
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`;

export const CallAgentBox = styled.div`
  display: none;
  width: 100%;

  @media (${mobileXXLargeMax}) {
    display: block;
  }
`;

export const Title = styled.h3`
  color: ${(props) => props.theme.textDark};
  font-size: 20px;
  text-align: center;
  margin: 15px 0;
  padding: 0 15px;
`;

export const AgentLogo = styled.img`
  display: block;
  max-width: 250px;
  max-height: 60px;
  margin: 0 auto;
`;

export const AwardLogo = styled.img`
  max-width: 250px;
  max-height: 80px;
  margin-bottom: 15px;
`;

export const AgentText = styled.p`
  color: ${(props) => props.theme.textMid};
  font-size: 14px;
  text-align: center;
`;

export const AgentBoxFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  height: 60px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid ${(props) => props.theme.keyline};
  transition: 300ms all;

  img {
    max-width: 200px;
    max-height: 40px;
  }

  ${AgentText} {
    text-align: left;
  }

  @media (${mobileXXLargeMax}) {
    ${RowAlignCenter} {
      display: none;
    }
  }
`;

export const NameAndPSRTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AgentBoxFixedButtons = styled.div<{ numBtns: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${({ numBtns }) => numBtns * 200}px;

  @media (max-width: ${({ numBtns }) => 200 + numBtns * 200}px) {
    max-width: ${({ numBtns }) => 200 + numBtns * 200}px;
    p {
      font-size: 13px;
    }
  }
`;

export const LicenceWarning = styled(Link)`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.primary};
  border-radius: 10px;
  display: inline-block;
  font-weight: bold;
  color: ${(props) => props.theme.white};
  margin-left: 5px;
`;
