import { Property } from '@propertypal/shared/src/types/property';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { Text } from '../typography';
import { Container } from './ShowHomesBar.style';

interface Props {
  property: Property;
}

const ShowHomesBar: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  if (
    !props.property.featuredDevelopmentContent ||
    !props.property.showHomeOpeningTime ||
    props.property.showHomeOpeningTime.byAppointment
  ) {
    return null;
  }

  return (
    <Container className="pp-show-homes-top-bar">
      <Text fontSize={16} align="center" color={theme.white}>
        <b>Show Home Open Viewing:</b> {props.property.showHomeOpeningTime.defaultText}
      </Text>
    </Container>
  );
};

export default ShowHomesBar;
