import React from 'react';
import Chevron from '../icons/Chevron';
import Button from './ReportProblemButton.style';

interface Props {
  onClick: () => void;
}

const ReportProblemButton: React.FC<Props> = (props) => {
  return (
    <Button type="button" onClick={props.onClick}>
      Report a problem <Chevron size={10} />
    </Button>
  );
};

export default ReportProblemButton;
