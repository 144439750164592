import { useEffect, useState } from 'react';

const useImagesLoaded = (images: string[]) => {
  const [imgsLoaded, setImgsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const loadImages = (image: string) => {
      return new Promise((resolve) => {
        const loadImg = new Image();

        loadImg.src = image;
        loadImg.onload = () => resolve(image);

        // still want to resolve when error loading image
        // just means we don't have to wait any further
        loadImg.onerror = (err) => resolve(err);
      });
    };

    Promise.all(images.map((img) => loadImages(img))).then(() => {
      setImgsLoaded(true);
    });
  }, [images]);

  return imgsLoaded;
};

export default useImagesLoaded;
