import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 60px 15px;
  margin: 0 auto;
`;

export default Container;
