import threeDTourIcon from '@propertypal/shared/src/resources/icons/tours/white-3D-tour-icon.svg?url';
import audioTourIcon from '@propertypal/shared/src/resources/icons/tours/white-audio-tour-icon.svg?url';
import bookTourIcon from '@propertypal/shared/src/resources/icons/tours/white-brochure-icon.svg?url';
import floorPlanIcon from '@propertypal/shared/src/resources/icons/tours/white-floorplan-tour.svg?url';
import videoTourIcon from '@propertypal/shared/src/resources/icons/tours/white-play-icon.svg?url';
import genericTourIcon from '@propertypal/shared/src/resources/icons/tours/white-virtual-tour-genric-icon.svg?url';
import virtualTourIcon from '@propertypal/shared/src/resources/icons/tours/white-virtual-tour-icon.svg?url';
import { GalleryItem } from '@propertypal/shared/src/utils/property/getGalleryImages';
import React, { FunctionComponent, ImgHTMLAttributes } from 'react';

interface Props {
  image: GalleryItem;
}

const ICON_MAP: { [key: string]: ImgHTMLAttributes<any> } = {
  generic: genericTourIcon,
  'virtual-tour': genericTourIcon,
  '360-tour': virtualTourIcon,
  '3d-tour': threeDTourIcon,
  audio: audioTourIcon,
  book: bookTourIcon,
  floorplan: floorPlanIcon,
  video: videoTourIcon,
};

const TourIcon: FunctionComponent<Props> = (props) => {
  const { image } = props;
  const icon = ICON_MAP[image.tourType || image.type];

  if (icon) {
    return <img src={icon.src} width={icon.width} height={icon.height} alt="icon" />;
  }

  return null;
};

export default TourIcon;
