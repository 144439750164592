import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 60px 0;
`;

export const DescriptionBox = styled.div`
  color: ${(props) => props.theme.textDark};
  transition: 600ms all;
  overflow: hidden;

  img {
    max-width: 500px;
    max-height: 300px;
    float: right;
    margin-left: 30px;
    vertical-align: middle;
  }

  ul {
    padding-left: 25px;

    li {
      margin: 5px 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 15px 0;
  }

  dt {
    margin: 15px 0 0;
  }

  dd,
  p {
    margin: 15px 0;
  }

  @media (${laptopMediumMax}) {
    img {
      display: none;
    }
  }

  @media print {
    max-height: none !important;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 80px;
  padding: 50px 5px 5px 5px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);

  button {
    font-size: 14px;
  }
`;

export const OverlayBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  @media (${laptopMediumMax}) {
    display: none;
  }
`;

export const OverlayImage = styled.img`
  max-height: 300px;
  max-width: 500px;
  display: block;
`;

export const OverlayFade = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;

  img {
    width: 200px;
    height: 100px;
  }

  p {
    color: ${(props) => props.theme.white};
    margin-top: 15px;
    text-align: center;
  }
`;
