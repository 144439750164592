import useInView from '@propertypal/shared/src/hooks/useInView';
import { Development } from '@propertypal/shared/src/types/development';
import { getMainImage } from '@propertypal/shared/src/utils/property';
import { IMAGE_LOCATOR_LARGE } from '@propertypal/web-app/src/constants/imageLocator';
import React, { FunctionComponent, useRef } from 'react';
import Loader from 'react-spinners/PulseLoader';
import { useTheme } from 'styled-components';
import PageWrapper from '../layout/PageWrapper';
import { Container, DevelopmentBox, LoadingBox, TextBox, Title } from './OtherDevelopments.style';
import PropertyBanner from './PropertyBanner';

interface Props {
  onInView: () => void;
  data?: Development[];
}

const OtherDevelopments: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const container = useRef<HTMLDivElement>(null);
  const developerName = props.data?.length ? props.data[0].account.organisation : '';
  useInView(container, { threshold: 0.5, rootMargin: '0px 0px 800px' }, props.onInView);

  if (props.data && props.data.length === 0) {
    return null;
  }

  return (
    <div ref={container} data-testid="other-developments">
      <PageWrapper className="pp-other-developments" containerStyle={{ paddingTop: 40 }}>
        <Title className="pp-other-developments-title">Other Developments by {developerName}</Title>

        {!props.data && (
          <LoadingBox data-testid="other-developments-loading">
            <Loader color={theme.backgroundLight} />
          </LoadingBox>
        )}

        {props.data && (
          <Container>
            {props.data.map((development) => {
              // @ts-ignore
              const [image] = getMainImage(development, IMAGE_LOCATOR_LARGE);

              return (
                <DevelopmentBox
                  className="pp-other-development-box"
                  href={development.path}
                  key={development.id}
                  prefetch={false}
                >
                  {/* @ts-ignore */}
                  <PropertyBanner property={development} />

                  <img src={image} alt={development.displayAddress} />

                  <TextBox className="other-developments-text-box">
                    <h3>{development.displayAddressLine1}</h3>
                    <p>{development.town}</p>
                  </TextBox>
                </DevelopmentBox>
              );
            })}
          </Container>
        )}
      </PageWrapper>
    </div>
  );
};

export default OtherDevelopments;
