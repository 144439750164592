/* eslint-disable no-undef */
import { Property } from '../../types';
import { roundOff } from '../number';

const getDefaultDepositPercent = (price: number): number => {
  let depositPercentage = 20;

  if (price && price <= 100000) depositPercentage = 10;
  else if (price && price <= 200000) depositPercentage = 15;
  return depositPercentage;
};

const getDefaultDeposit = (property: Property): number => {
  const price = property.price?.price || property.price?.minPrice;
  if (price) return Math.ceil(price * (getDefaultDepositPercent(price) / 100));
  return 0;
};

const getDefaultTerm = (property: Property): number => {
  const { price } = property;
  if ((price && price.minPrice && price.minPrice <= 100000) || (price && price.price && price.price <= 100000))
    return 25;
  return 30;
};

const getDepositPercentage = (deposit: number, value: number) => {
  if (value === 0) return 0;

  return roundOff((deposit / value) * 100, 2);
};

export { getDefaultTerm, getDefaultDeposit, getDefaultDepositPercent, getDepositPercentage };
