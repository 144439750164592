import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { faVideoPlus } from '@fortawesome/pro-regular-svg-icons/faVideoPlus';
import FloorPlanIcon from '@propertypal/shared/src/resources/icons/property/floorplan-icon-black.svg';
import SitemapIcon from '@propertypal/shared/src/resources/icons/property/sitemap-black.svg';
import { Property } from '@propertypal/shared/src/types/property';
import getPropertyFloorPlan from '@propertypal/shared/src/utils/property/getPropertyFloorPlan';
import getPropertyTour from '@propertypal/shared/src/utils/property/getPropertyTour';
import getPropertyVideos from '@propertypal/shared/src/utils/property/getPropertyVideos';
import IMAGE_LOCATOR from '@propertypal/web-app/src/constants/imageLocator';
import React, { FunctionComponent, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import {
  Container,
  Content,
  DropdownButton,
  IconLink,
  IconWrapper,
  LinkButton,
  Options,
  Text,
} from './SlideshowLinks.style';

interface LinkType {
  text: string;
  url: string;
  icon?: any;
  svg?: any;
  shallow?: boolean;
}

interface Props {
  getReplaceUrl: (url: string) => string;
  property: Property;
  hasSitemap?: boolean;
  hasFeaturedBrochure?: boolean;
}

const getListButtonLinks = (
  getReplaceUrl: (url: string) => string,
  property: Property,
  hasSitemap?: boolean,
  hasFeaturedBrochure?: boolean,
) => {
  const propertyVideo = getPropertyVideos(property);
  const propertyTour = getPropertyTour(property);
  const propertyFloorPlans = getPropertyFloorPlan(property, IMAGE_LOCATOR);

  const links: LinkType[] = [
    {
      text: 'Map',
      url: getReplaceUrl('/map'),
      icon: faMapMarkerAlt,
    },
  ];

  if (property.images?.length) {
    links.push({
      text: 'Gallery',
      url: getReplaceUrl('/gallery'),
      icon: faImages,
    });
  }

  if (propertyTour && propertyTour.tourIndex !== undefined) {
    links.push({
      text: 'Tour',
      url: getReplaceUrl(`/tour${propertyTour.tourIndex + 1}`),
      icon: faVideoPlus,
    });
  }

  if (propertyVideo) {
    links.push({
      text: 'Video',
      url:
        propertyVideo.tourIndex !== undefined
          ? getReplaceUrl(`/tour${propertyVideo.tourIndex + 1}`)
          : getReplaceUrl('/video'),
      icon: faVideo,
    });
  }

  if (propertyFloorPlans?.length) {
    links.push({
      text: 'Floor Plans',
      url: getReplaceUrl('/floor-plans'),
      svg: <FloorPlanIcon />,
      shallow: true,
    });
  }

  if (hasFeaturedBrochure) {
    links.push({
      text: 'Brochure',
      url: `${property.path}/brochure/1`,
      icon: faFilePdf,
    });
  }

  if (hasSitemap) {
    links.push({
      text: 'Site Map',
      url: getReplaceUrl('/sitemap'),
      svg: <SitemapIcon />,
    });
  }

  return links;
};

const SlideshowLinks: FunctionComponent<Props> = ({ getReplaceUrl, property, hasSitemap, hasFeaturedBrochure }) => {
  const linkButtons = getListButtonLinks(getReplaceUrl, property, hasSitemap, hasFeaturedBrochure);
  const showOptions = linkButtons.length > 4;
  const [dropdownActive, setDropdownActive] = useState(false);
  const theme = useTheme();

  return (
    <Container>
      <Content showOptions={showOptions}>
        {linkButtons.map((link) => (
          <LinkButton key={link.url} href={link.url} prefetch={false} shallow>
            {!!link.icon && <FontAwesomeIcon icon={link.icon} color={theme.textDark} style={{ fontSize: 22 }} />}

            {link.svg}

            <Text>{link.text}</Text>
          </LinkButton>
        ))}
      </Content>

      <Options
        className="pp-summary-box"
        showOptions={showOptions}
        style={{ maxHeight: dropdownActive ? 200 : 0, marginBottom: dropdownActive ? -15 : 0 }}
      >
        <DropdownButton onClick={() => setDropdownActive(!dropdownActive)}>
          <Text>Options</Text>
          <FontAwesomeIcon
            icon={dropdownActive ? faChevronUp : faChevronDown}
            color={theme.textDark}
            style={{ fontSize: 18, marginLeft: 20 }}
          />
        </DropdownButton>

        <IconWrapper>
          {linkButtons.map((link) => (
            <IconLink key={link.url} href={link.url} prefetch={false} shallow>
              {!!link.icon && (
                <FontAwesomeIcon icon={link.icon} color={theme.textDark} style={{ color: 'inherit', fontSize: 28 }} />
              )}

              {link.svg}
            </IconLink>
          ))}
        </IconWrapper>
      </Options>
    </Container>
  );
};

export default SlideshowLinks;
