import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faWifi } from '@fortawesome/pro-solid-svg-icons/faWifi';
import BROADBAND_INFO from '@propertypal/shared/src/constants/broadband';
import { Broadband } from '@propertypal/shared/src/types/property';
import formatBroadbandData from '@propertypal/shared/src/utils/property/formatBroadbandData';
import React, { FunctionComponent, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Row } from '../layout';
import PageWrapper from '../layout/PageWrapper';
import WhiteModal from '../modal/WhiteModal';
import InfoIcon from '../schools/InfoIcon';
import { MidHeading, Text } from '../typography';
import { Container, StyledTable, Title } from './BroadbandTable.style';

interface Props {
  displayAddress: string;
  broadband: Broadband;
}

const BroadbandTable: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const data = formatBroadbandData(props.broadband);
  const [tooltipWindow, setTooltipWindow] = useState('');

  const handleTooltipClick = (type: string) => () => {
    setTooltipWindow(type);
  };

  return (
    <PageWrapper>
      <Container>
        <Row>
          <FontAwesomeIcon
            icon={faWifi}
            color={theme.primary}
            style={{ fontSize: 24, marginRight: 10, marginTop: 5 }}
          />

          <div>
            <Title>Fixed-line broadband services at {props.displayAddress}</Title>

            {props.broadband.estimated && (
              <Text fontSize={12}>
                Exact broadband speeds are unknown for this property, we&apos;ve estimated the likely speed based on
                other properties in the postcode.
              </Text>
            )}
          </div>
        </Row>

        <StyledTable>
          <thead>
            <tr>
              <th>Broadband type</th>
              <th>Highest available download speed</th>
              <th>Highest available upload speed</th>
              <th>Availability</th>
            </tr>
          </thead>

          <tbody>
            {data.map((row) => (
              <tr key={row.label}>
                <td>
                  {row.label} <InfoIcon onClick={handleTooltipClick(row.label)} color={theme.textDark} />
                </td>
                <td>{row.download}</td>
                <td>{row.upload}</td>
                <td>
                  <FontAwesomeIcon
                    icon={row.availability ? faCheck : faTimes}
                    color={row.availability ? theme.green : theme.red}
                    style={{ fontSize: 24 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>

        <Text fontSize={12}>
          The speeds indicated on our checker represent the maximum estimated fixed-line speeds as predicted by the
          network operators providing services in a particular area. Please note that these are estimates, and actual
          service availability and speeds received at a given property may differ.
        </Text>

        <Text fontSize={12} mt={10}>
          Besides fixed-line services, options for 4G, 5G, or satellite broadband may be available at the address,
          contingent upon the necessary hardware. These alternatives are not reflected in the checker&apos;s estimates.
        </Text>
      </Container>

      <WhiteModal show={!!tooltipWindow} onClose={() => setTooltipWindow('')} maxWidth={500}>
        <MidHeading>{tooltipWindow}</MidHeading>
        <Text mt={10}>{BROADBAND_INFO[tooltipWindow]}</Text>
      </WhiteModal>
    </PageWrapper>
  );
};

export default BroadbandTable;
