import { laptopMediumMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';

const OPTIONS_BREAKPOINT = 'max-width: 820px';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  z-index: 2;
`;

export const Content = styled.div<{ showOptions: boolean }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -40px;
  right: 30px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 45px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 2px;

  @media (${laptopMediumMax}) {
    right: 15px;
  }

  @media (${mobileXXLargeMax}) {
    top: -30px;
  }

  ${(props) =>
    props.showOptions &&
    `
    @media (${OPTIONS_BREAKPOINT}) {
      display: none;
    }
  `}
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.textDark};
  margin-left: 10px;
  white-space: nowrap;
`;

export const LinkButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border-right: 1px solid ${(props) => props.theme.backgroundLightest};

  &:last-child {
    border-right: none;
  }

  // prevents custom new dev styles overwriting the svg color
  svg {
    color: ${({ theme }) => theme.textDark};
  }

  @media (${mobileXXLargeMax}) {
    &:not(:first-child) ${Text} {
      display: none;
    }
  }
`;

export const Options = styled.div<{ showOptions: boolean }>`
  position: relative;
  display: none;
  justify-content: center;
  padding: 0 65px 10px;

  ${(props) =>
    props.showOptions &&
    `
    @media (${OPTIONS_BREAKPOINT}) {
      display: flex;
    }
  `}
`;

export const DropdownButton = styled.button`
  width: calc(100% - 130px);
  max-width: 360px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -28px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 45px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 2px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  padding-top: 45px;
`;

export const IconLink = styled(Link)`
  display: block;
  margin: 0 12px;
`;
