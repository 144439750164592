import { propertyEventTracker } from '@propertypal/shared/src/reducers/properties';
import { useAppDispatch } from '@propertypal/shared/src/reducers/store';
import { Property } from '@propertypal/shared/src/types/property';
import { GalleryItem } from '@propertypal/shared/src/utils/property/getGalleryImages';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Chevron from '../icons/Chevron';
import { LinkText } from '../typography';
import { Container, DescriptionBox, Overlay, OverlayBox, OverlayFade, OverlayImage } from './PropertyDescription.style';
import TourIcon from './TourIcon';

interface Props {
  images: GalleryItem[];
  property: Property;
  onItemClick: (galleryItem: GalleryItem) => void;
  onImageClick: (index: number) => void;
}

const MAX_HEIGHT = 300;
const OPEN_HEIGHT = 9999;

const getSecondImage = (images: GalleryItem[]): [GalleryItem | null, number] => {
  if (images.length === 0) {
    return [null, 0];
  }

  const tourIndex = images.findIndex((image) => image.type === 'webview');
  if (tourIndex >= 0) return [images[tourIndex], tourIndex];

  const videoIndex = images.findIndex((image) => image.type === 'video');
  if (videoIndex >= 0) return [images[videoIndex], videoIndex];

  const index = images.length > 1 ? 1 : 0;

  return [images[index], index];
};

const PropertyDescription: FunctionComponent<Props> = (props) => {
  const [galleryItem, index] = getSecondImage(props.images);
  const image = galleryItem ? `<img src="${galleryItem.image}" alt="property description image" />` : '';
  const description = `${image}${props.property.description}`;
  const [maxHeight, setMaxHeight] = useState<number>(OPEN_HEIGHT);
  const descriptionBox = useRef<HTMLDivElement>(null);
  const asyncDispatch = useAppDispatch();

  const onImageClick = () => {
    if (galleryItem?.type === 'image') props.onImageClick(index);
    else if (galleryItem) props.onItemClick(galleryItem);
  };

  const onReadMoreClick = () => {
    setMaxHeight(OPEN_HEIGHT);

    asyncDispatch(
      propertyEventTracker(props.property.id, props.property.type === 'development', {
        eventType: 'READ_MORE',
      }),
    );
  };

  useEffect(() => {
    if (descriptionBox.current) {
      if (descriptionBox.current.getBoundingClientRect().height > MAX_HEIGHT) {
        setMaxHeight(MAX_HEIGHT);
      }
    }
  }, []);

  if (!props.property.description) {
    return null;
  }

  return (
    <Container>
      <DescriptionBox
        className="pp-property-description"
        ref={descriptionBox}
        style={{ maxHeight }}
        dangerouslySetInnerHTML={{ __html: description }}
        data-testid="propertyDescription"
      />

      {maxHeight < OPEN_HEIGHT && (
        <Overlay className="pp-description-overlay hide-print">
          <LinkText
            as="button"
            aria-label="Expand property description"
            className="pp-description-read-more"
            onClick={onReadMoreClick}
            data-testid="readMoreButton"
            type="button"
          >
            Read More <Chevron size={9} />
          </LinkText>
        </Overlay>
      )}

      {!!galleryItem && (
        <OverlayBox>
          <OverlayImage src={galleryItem.image} onClick={onImageClick} data-testid="property-description-image" />

          {galleryItem.type !== 'image' && (
            <OverlayFade>
              <TourIcon image={galleryItem} />

              <p>{galleryItem.text}</p>
            </OverlayFade>
          )}
        </OverlayBox>
      )}
    </Container>
  );
};

export default PropertyDescription;
