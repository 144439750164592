import styled from 'styled-components';

export const HorizDivider = styled.div<{ mb: number | undefined; mt: number | undefined }>`
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding: 0;
  transition:
    height 0ms 0ms,
    opacity 0.6s 0ms,
    margin 0ms 0ms;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : `0px;`)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : `0px;`)};
`;

export const HorizDividerText = styled.span<{ bg?: string; color?: string }>`
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  ${({ color }) => (color ? `color: ${color};` : '')}
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 9999px;
    height: 2px;
    background: ${({ bg }) => bg || '#d8d8d8'};
    right: 100%;
    margin-right: 15px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 9999px;
    height: 2px;
    background: ${({ bg }) => bg || '#d8d8d8'};
    left: 100%;
    margin-left: 15px;
  }
`;
