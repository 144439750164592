import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 30px 0;
  padding: 0 15px;
  font-size: 13px;
  color: ${(props) => props.theme.backgroundMid};
  text-align: center;
  line-height: 24px;
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.textLight};
`;

export const LinkText = styled(Link)`
  text-decoration: underline;
  transition: 300ms all;

  &:hover {
    color: ${(props) => props.theme.textMid};
  }
`;
