import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons/faPlayCircle';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { Property } from '@propertypal/shared/src/types/property';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { Container, Content, IconWrapper, ItemRow } from './PropertyPdfBar.style';

interface Props {
  property: Property;
  onItemClick: (nextUrl: string) => void;
  isFeaturedDevelopment: boolean;
}

const PropertyPdfBar: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const videoTitle = `Watch The ${props.property.type === 'development' ? 'Development' : 'Property'} Video`;

  if (
    !props.property.videos?.length &&
    !props.property['360Tours']?.length &&
    !props.property.brochures?.length &&
    props.property.type === 'development'
  ) {
    return null;
  }

  return (
    <Container className="pp-pdf-bar hide-print">
      <Content>
        {!!props.property.videos?.length && (
          <ItemRow as="button" onClick={() => props.onItemClick('/video')} data-testid="watchVideoButton">
            <IconWrapper className="pp-icon-wrapper">
              <FontAwesomeIcon
                icon={faPlayCircle}
                color={theme.textDark}
                style={{ fontSize: 24 }}
                className="pp-video-icon"
              />
            </IconWrapper>
            <p>{videoTitle}</p>
          </ItemRow>
        )}

        {props.property['360Tours'] &&
          props.property['360Tours'].map((tour) => (
            <ItemRow
              key={tour.displayOrder}
              as="button"
              onClick={() => props.onItemClick(`/tour${tour.displayOrder}`)}
              data-testid="viewTourButton"
            >
              <IconWrapper className="pp-icon-wrapper">
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  color={theme.textDark}
                  style={{ fontSize: 24 }}
                  className="pp-video-icon"
                />
              </IconWrapper>

              <p>{tour.type === 'video' ? videoTitle : 'Take The 3D Tour'}</p>
            </ItemRow>
          ))}

        {props.isFeaturedDevelopment &&
          props.property.brochures?.map((pdf, index) => (
            <ItemRow as="button" key={pdf.url} onClick={() => props.onItemClick(`/brochure/${index + 1}`)}>
              <IconWrapper className="pp-icon-wrapper">
                <FontAwesomeIcon
                  icon={faFilePdf}
                  color={theme.textDark}
                  style={{ fontSize: 24 }}
                  className="pp-pdf-icon"
                />
              </IconWrapper>

              <p>
                {pdf.title} ({pdf.sizeDescription})
              </p>
            </ItemRow>
          ))}

        {!props.isFeaturedDevelopment &&
          props.property.brochures?.map((pdf) => (
            <ItemRow key={pdf.url} href={pdf.url} download={pdf.title} target="_blank" rel="noreferrer">
              <IconWrapper className="pp-icon-wrapper">
                <FontAwesomeIcon
                  icon={faFilePdf}
                  color={theme.textDark}
                  style={{ fontSize: 24 }}
                  className="pp-pdf-icon"
                />
              </IconWrapper>

              <p>
                {pdf.title} ({pdf.sizeDescription})
              </p>
            </ItemRow>
          ))}

        {props.property.type === 'property' && (
          <ItemRow href={`/property-print?property=${props.property.id}`} target="_blank">
            <IconWrapper className="pp-icon-wrapper">
              <FontAwesomeIcon
                icon={faPrint}
                color={theme.textDark}
                style={{ fontSize: 24 }}
                className="pp-print-icon"
              />
            </IconWrapper>

            <p>Printer Friendly Version</p>
          </ItemRow>
        )}
      </Content>
    </Container>
  );
};

export default PropertyPdfBar;
