import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import useWindowSize from '@propertypal/shared/src/hooks/useWindowSize';
import detachedHouse from '@propertypal/shared/src/resources/icons/filters/detached-icon.svg?url';
import panoramicIcon from '@propertypal/shared/src/resources/icons/property/panoramic-icon.svg?url';
import { GalleryItem } from '@propertypal/shared/src/utils/property/getGalleryImages';
import useTouchDevice from '@propertypal/web-app/src/hooks/useTouchDevice';
import React, {
  CSSProperties,
  FunctionComponent,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import 'swiper/css';
import Magnifier from '../magnifier/Magnifier';
import { Text } from '../typography';
import {
  ArrowButton,
  Container,
  CounterContainer,
  Curtain,
  ImageBox,
  ImageOverlay,
  NoImageBackground,
  PanoramicIconBox,
  PhotoCounter,
  ServerSideBox,
} from './PropertySlider.style';
import TourIcon from './TourIcon';

interface Props {
  alt: string;
  images: GalleryItem[];
  onClick?: (item: GalleryItem) => void;
  onCounterClick?: (item: GalleryItem) => void;
  imageIndex: number;
  setImageIndex: (index: number) => void;
  setHeight: (windowWidth: number, windowHeight: number, imageHeight: number) => number;
  centerImageCounter?: boolean;
  renderImageOverlay?: () => ReactElement;
  fullscreen?: boolean;
  enableKeyboardControls?: boolean;
  updateOnIndexChange?: boolean;
  children?: ReactNode;
  sitemap?: boolean;
}

const MAX_RATIO = 0.66;
const LOOPED_SLIDES = 4;

export const getRealIndex = (swiper: SwiperClass, images: GalleryItem[]) => {
  const index = swiper.activeIndex - (swiper.loopedSlides || 0);

  if (index < 0) {
    return images.length + index;
  }

  if (index >= images.length) {
    return index - images.length;
  }

  return index;
};

const getImageWidth = (
  width: number,
  height: number,
  windowWidth: number | undefined,
  sliderHeight: number,
  useMaxWidth: boolean,
): [number, boolean] => {
  const ratio = height / width;
  const imageWidth = sliderHeight / ratio;

  if (useMaxWidth) {
    if (ratio < MAX_RATIO) {
      return [Math.round(Math.min(sliderHeight / MAX_RATIO, windowWidth || 0)), true];
    }

    return [Math.round(Math.min(imageWidth, windowWidth || 0)), false];
  }

  return [Math.round(imageWidth), false];
};

const calculateRatio = (images: GalleryItem[]) => {
  let ratio: number = 0;

  images.forEach((image) => {
    const iRatio = image.height / image.width;
    if (ratio === 0 || (ratio > iRatio && iRatio < MAX_RATIO)) {
      ratio = iRatio;
    }
  });

  return ratio;
};

export const calculateHeight = (
  images: GalleryItem[],
  setHeight: Props['setHeight'],
  windowWidth?: number,
  windowHeight?: number,
) => {
  if (windowWidth === undefined || windowHeight === undefined) {
    return 200;
  }

  let height: number = 0;

  images?.forEach((image) => {
    const ratio = image.height / image.width;
    const imageHeight = ratio * windowWidth;

    if (height === 0) {
      height = imageHeight;
    } else if (imageHeight < height && ratio > MAX_RATIO) {
      height = imageHeight;
    }
  });

  return setHeight(windowWidth, windowHeight, height);
};

export const getActiveIndexes = (currentIndex: number, totalIndexes: number) => {
  if (totalIndexes <= 5) {
    return [0, 1, 2, 3, 4];
  }

  const indexes = [];

  for (let i = currentIndex - 2; i <= currentIndex + 2; i += 1) {
    if (i < 0) {
      indexes.push(totalIndexes + i);
    } else if (i >= totalIndexes) {
      indexes.push(i - totalIndexes);
    } else {
      indexes.push(i);
    }
  }

  return indexes;
};

const PropertySlider: FunctionComponent<Props> = (props) => {
  const windowSize = useWindowSize(!props.fullscreen);
  const theme = useTheme();
  const touchDevice = useTouchDevice();
  const lastKeyPress = useRef<number>(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const height = calculateHeight(props.images, props.setHeight, windowSize.width, windowSize.height);
  const aspectRatio = calculateRatio(props.images);
  const activeIndexes = getActiveIndexes(props.imageIndex, props.images.length);

  const incrementIndex = () => {
    swiper?.slideNext();
  };

  const decrementIndex = () => {
    swiper?.slidePrev();
  };

  const handleCounterClick = () => {
    if (props.onCounterClick) {
      props.onCounterClick(props.images[props.imageIndex]);
    }
  };

  const handleImageClick = (image: GalleryItem) => {
    if (swiper) {
      const imageIndex = getRealIndex(swiper, props.images);

      if (image.index === imageIndex) {
        if (props.onClick) props.onClick(image);
      } else if (
        (image.index < imageIndex || (imageIndex === 0 && image.index === props.images.length - 1)) &&
        !(imageIndex === props.images.length - 1 && image.index === 0)
      ) {
        decrementIndex();
      } else {
        incrementIndex();
      }
    }
  };

  const handleKeyPress: KeyboardEventHandler = (event) => {
    const now = new Date().getTime();

    if (document.scrollingElement && document.scrollingElement.scrollTop <= 0 && now > lastKeyPress.current + 500) {
      if (event.key === 'ArrowRight') {
        incrementIndex();
      } else if (event.key === 'ArrowLeft') {
        decrementIndex();
      }

      lastKeyPress.current = now;
    }
  };

  const handleKeyUp = () => {
    lastKeyPress.current = 0;
  };

  useEventListener('keydown', handleKeyPress, props.enableKeyboardControls);
  useEventListener('keyup', handleKeyUp, props.enableKeyboardControls);

  useEffect(() => {
    if (props.updateOnIndexChange && swiper && getRealIndex(swiper, props.images) !== props.imageIndex) {
      swiper.slideTo(props.imageIndex + (swiper.loopedSlides || 0), 0);
    }
  }, [props.updateOnIndexChange]);

  if (!windowSize.width) {
    return (
      <ServerSideBox aspectRatio={aspectRatio}>
        {props.images.slice(0, 3).map((image) => {
          return <img key={image.image} src={image.image} alt={props.alt} />;
        })}
      </ServerSideBox>
    );
  }

  if (props.images.length === 1) {
    const [width] = getImageWidth(
      props.images[0].width,
      props.images[0].height,
      windowSize.width,
      height,
      !!props.fullscreen,
    );
    return (
      <Container role="region" aria-label="property images" tabIndex={0} style={{ height }}>
        {props.children}
        {props.renderImageOverlay && props.renderImageOverlay()}

        {props.sitemap ? (
          <Magnifier id="property-slider-image-1" src={props.images[0].image} width={width} alt={props.alt} />
        ) : (
          <img id="property-slider-image-1" src={props.images[0].image} style={{ width }} alt={props.alt} />
        )}
      </Container>
    );
  }

  if (props.images.length === 0) {
    return (
      <Container role="region" aria-label="property images" tabIndex={0} style={{ height: '60vh' }}>
        <NoImageBackground data-testid="no-image">
          {props.children}
          {props.renderImageOverlay && props.renderImageOverlay()}

          <img src={detachedHouse.src} width={detachedHouse.width} height={detachedHouse.height} alt="house" />
          <Text>No photo available</Text>
        </NoImageBackground>
      </Container>
    );
  }

  return (
    <Container style={{ height }}>
      {props.images && (
        <CounterContainer center={props.centerImageCounter}>
          <PhotoCounter
            aria-label="Open photos dialog"
            onClick={handleCounterClick}
            as={props.onCounterClick ? 'button' : 'div'}
            data-testid="photoCounter"
          >
            <FontAwesomeIcon
              icon={faCamera}
              color={theme.white}
              style={{ fontSize: 12, marginTop: 2, marginRight: 4 }}
            />
            <Text fontSize={12} color={theme.white}>
              {props.imageIndex + 1}/{props.images.length}
            </Text>
          </PhotoCounter>
        </CounterContainer>
      )}

      {/* Blocks ability on iOS to swipe history back and swipe the slider at the same time */}
      {/* Doing both at the same time creates a race condition between history back and history replace */}
      <Curtain style={{ left: 0 }} />
      <Curtain style={{ right: 0 }} />

      <Swiper
        initialSlide={props.imageIndex}
        onSlideChange={(mSwiper) => {
          const index = getRealIndex(mSwiper, props.images);

          // Timeout prevents state updates happening during slide transition which causes animation lag
          setTimeout(() => {
            props.setImageIndex(index);
          }, 300);
        }}
        centeredSlides
        onSwiper={setSwiper}
        simulateTouch
        slidesPerView="auto"
        loopedSlides={LOOPED_SLIDES}
        spaceBetween={10}
        maxBackfaceHiddenSlides={5}
        loop
        style={{ height: '100%' }}
        // prevent swiping to navigate on sitemap images on touch devices
        allowTouchMove={!(props.sitemap && touchDevice)}
      >
        {props.children}

        {/* allow nav icons on sitemap images on touch devices */}
        {(props.sitemap || !touchDevice) && (
          <>
            <ArrowButton
              aria-label="Previous image"
              side="left"
              onClick={decrementIndex}
              data-testid="slider-left"
              sitemapTouch={touchDevice && props.sitemap}
            >
              <FontAwesomeIcon icon={faAngleLeft} color={theme.white} style={{ fontSize: 60 }} />
            </ArrowButton>

            <ArrowButton
              aria-label="Next image"
              side="right"
              onClick={incrementIndex}
              data-testid="slider-right"
              sitemapTouch={touchDevice && props.sitemap}
            >
              <FontAwesomeIcon icon={faAngleRight} color={theme.white} style={{ fontSize: 60 }} />
            </ArrowButton>
          </>
        )}

        {props.images.map((image, index) => {
          const [slideWidth, isPanoramic] = getImageWidth(image.width, image.height, windowSize.width, height, true);
          const [imageWidth] = getImageWidth(image.width, image.height, windowSize.width, height, !!props.fullscreen);
          const marginLeft = imageWidth > slideWidth ? (slideWidth - imageWidth) / 2 : 0;
          const imageHeight = props.fullscreen ? 'auto' : '100%';

          const style: CSSProperties = {
            width: imageWidth,
            marginLeft,
            display: 'block',
            backgroundColor: theme.backgroundLightest,
            height: imageHeight,
          };

          return (
            <SwiperSlide key={image.key} style={{ width: slideWidth }}>
              <ImageBox
                onClick={() => handleImageClick(image)}
                data-testid={`slide-${index + 1}`}
                style={{ height: imageHeight }}
              >
                {activeIndexes.includes(index) &&
                  (props.sitemap ? (
                    <Magnifier
                      id={`property-slider-image-${index}`}
                      src={image.image}
                      width={imageWidth}
                      alt={`${props.alt} ${index + 1} of ${props.images?.length}`}
                      style={style}
                    />
                  ) : (
                    <img
                      id={`property-slider-image-${index}`}
                      src={image.image}
                      alt={`${props.alt} ${index + 1} of ${props.images?.length}`}
                      style={style}
                    />
                  ))}

                {props.renderImageOverlay && props.renderImageOverlay()}

                {isPanoramic && !props.fullscreen && (
                  <PanoramicIconBox>
                    <img
                      src={panoramicIcon.src}
                      width={panoramicIcon.width}
                      height={panoramicIcon.height}
                      alt="panoramic icon"
                    />
                  </PanoramicIconBox>
                )}

                {(image.type === 'webview' || image.type === 'video') && (
                  <ImageOverlay>
                    <TourIcon image={image} />

                    <Text>{image.text}</Text>
                  </ImageOverlay>
                )}
              </ImageBox>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};

export default PropertySlider;
