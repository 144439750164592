export type KeyInfoTooltipContent = Record<string, { content: string; type?: 'title' | 'text' }>;

export const keyInfoTooltips: KeyInfoTooltipContent = {
  INTERNAL_AREA: {
    content: 'Property Size information is provided by the advertising Agent.',
    type: 'text',
  },
  SIZE: {
    content: 'Property Size information is provided by the advertising Agent.',
    type: 'text',
  },
  TENURE: {
    content:
      'The term tenure refers to the various ways that you can own a property. Typically it can be freehold, leasehold, or leasehold with a share of the freehold.',
  },
  STAMP_DUTY_SIMPLE: { content: 'Stamp Duty is a tax you might have to pay if you buy a property.' },
  STAMP_DUTY_COMPLEX: { content: 'Stamp Duty is a tax you might have to pay if you buy a property.' },
};
