import { Property } from '../../types/property';

export interface PropertyVideo {
  url: string;
  type: 'video' | 'tour';
  tourIndex?: number;
}

const getPropertyVideos = (property: Property): PropertyVideo | null => {
  if (property.videos?.length) {
    return {
      url: property.videos[0].url,
      type: 'video',
    };
  }

  if (property['360Tours']) {
    const index = property['360Tours']?.findIndex((tour) => tour.type === 'video');

    if (index >= 0) {
      return {
        url: property['360Tours'][index].url,
        type: 'tour',
        tourIndex: index,
      };
    }
  }

  return null;
};

export default getPropertyVideos;
