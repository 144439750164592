import { mobileXXLargeMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled, { css } from 'styled-components';

export const Content = styled.div<{ isFeaturedDevelopment: boolean }>`
  padding-bottom: 30px;
  margin: 30px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${({ isFeaturedDevelopment }) =>
    isFeaturedDevelopment &&
    `
    p b,
    p a,
    p button {
      color: inherit;
    }
  `}
`;

export const Title = styled.h2`
  display: inline-block;
  padding: 6px 10px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.05);
  color: ${({ theme }) => theme.textDark};
  border-radius: 4px;
`;

export const KeyInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-top: 15px;

  @media (${tabletMax}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (${mobileXXLargeMax}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const KeyInfoItem = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    margin-right: 10px;
    color: inherit;
  }
`;

export const KeyInfoTitle = styled.p<{ hasTooltip?: boolean }>`
  color: ${({ theme }) => theme.textDark};
  font-weight: bold;

  ${({ hasTooltip, theme }) =>
    hasTooltip
      ? css`
          display: flex;
          align-items: center;
          gap: 5px;

          button {
            display: inline-flex;
            color: ${theme.primary};

            svg {
              font-size: 16px;
            }
          }
        `
      : ''}
`;

export const KeyInfoText = styled.p<{ hasTooltip?: boolean }>`
  color: ${({ theme }) => theme.textLight};

  ${({ hasTooltip, theme }) =>
    hasTooltip
      ? css`
          display: flex;
          align-items: center;
          gap: 5px;

          button {
            display: inline-flex;
            color: ${theme.primary};

            svg {
              font-size: 16px;
            }
          }
        `
      : ''}

  button {
    font-size: 16px;
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }

  b {
    color: ${({ theme }) => theme.primary};
  }
`;

export const KeyInfoLoader = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const SubText = styled.p`
  color: ${({ theme }) => theme.backgroundMid};
  font-size: 12px;

  button {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }
`;

export const BottomRow = styled.div`
  background-color: ${({ theme }) => theme.backgroundLightest};
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 30px 0;

  p {
    color: ${({ theme }) => theme.textLight};
  }

  a {
    color: ${({ theme }) => theme.red};
    text-decoration: underline;
  }

  @media (${tabletMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
