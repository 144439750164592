import styled from 'styled-components';

export const SLIDE_WIDTH = 256;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

export const HtmlWrapper = styled.button`
  width: 100%;
  display: block;
`;

export const Image = styled.div<{ url: string }>`
  display: flex;
  width: ${SLIDE_WIDTH}px;
  height: 180px;
  background-image: url(${(props) => props.url});
  background-position: center;
  background-size: cover;
  margin: 10px 10px 10px 0;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export const SliderBox = styled.div`
  position: relative;
`;

export const ArrowButton = styled.button<{ side: 'right' | 'left' }>`
  position: absolute;
  top: 0;
  width: 80px;
  height: 100%;
  z-index: 2;

  ${(props) => props.side === 'right' && 'right: 0;'}
  ${(props) => props.side === 'left' && 'left: 0;'}
  
  & svg {
    filter: drop-shadow(0px 0px 6px rgb(0 0 0 / 0.5));
  }
`;
