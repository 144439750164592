import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons/faShareAlt';
import { Property } from '@propertypal/shared/src/types/property';
import Accordion from '@propertypal/web-ui/src/animation/Accordion';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import PropertyFavourite from '@propertypal/web-ui/src/property/PropertyFavourite';
import router from 'next/router';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import NoteItems from '../my/notes/NoteItems';
import { Container, Content, IconWrapper, Item, ItemRow, NotesWrapper } from './PropertySocialBar.style';

interface Props {
  property: Property;
  favourited: boolean;
  onFavourite: (favourited: boolean) => Promise<boolean>;
  onShare: () => void;
  loggedIn: boolean;
}

const PropertySocialBar: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [notesOpen, setNotesOpen] = useState(props.loggedIn);
  const propertyFavourite = useRef<HTMLButtonElement>(null);

  const toggleNotes = () => {
    if (notesOpen) {
      setNotesOpen(false);
    } else if (!props.loggedIn) {
      router.push(`/login?sourcePath=${window.location.pathname}`);
    } else {
      setNotesOpen(true);
    }
  };

  return (
    <Container className="pp-social-bar hide-print">
      <Content>
        <ItemRow>
          <Item as="div">
            <PropertyFavourite
              key="socialBarFavourite"
              innerRef={propertyFavourite}
              favourited={props.favourited}
              onFavourite={props.onFavourite}
            />

            {/* without function curry, click event will throw illegal invocation error */}
            <button onClick={() => propertyFavourite.current?.click()} className="buttonText" type="button">
              Favourite This Property
            </button>
          </Item>

          {props.property.published && (
            <>
              <Item data-testid="sharePropertyButton" onClick={props.onShare} type="button">
                <IconWrapper className="pp-icon-wrapper">
                  <FontAwesomeIcon
                    icon={faShareAlt}
                    color={theme.textDark}
                    style={{ fontSize: 24 }}
                    className="pp-social-icon"
                  />
                </IconWrapper>
                <p>Share This Property</p>
              </Item>

              <Item data-testid="addPersonalNoteButton" onClick={toggleNotes} type="button">
                <IconWrapper className="pp-icon-wrapper">
                  <FontAwesomeIcon
                    icon={faPencil}
                    color={theme.textDark}
                    style={{ fontSize: 24 }}
                    className="pp-social-icon"
                  />
                </IconWrapper>
                <p>Add A Personal Note</p>
              </Item>
            </>
          )}
        </ItemRow>

        {props.loggedIn && props.property.published && (
          <Accordion isOpen={notesOpen}>
            <NotesWrapper data-testid="notesWrapper">
              <NoteItems property={props.property} notes={props.property.notes} />
            </NotesWrapper>
          </Accordion>
        )}
      </Content>
    </Container>
  );
};

export default PropertySocialBar;
