import dayjs from 'dayjs';
import { OpeningTime } from '../../types/agents';

interface TimeLabel {
  firstDay: string;
  lastDay?: string;
  openingTime: string;
  closingTime: string;
}

const getOpeningTimes = (openingTimes: OpeningTime[]) => {
  const timeLabels: TimeLabel[] = [];

  openingTimes.forEach((ot) => {
    const day = dayjs(ot.openTime).format('ddd');
    const openingTime = ot.closed ? 'Closed' : ot.byAppointment ? 'By Appointment' : dayjs(ot.openTime).format('h:mma');
    const closingTime = ot.closed || ot.byAppointment ? '' : dayjs(ot.closeTime).format('h:mma');
    const lastDay = timeLabels[timeLabels.length - 1];

    if (ot.lunchBreak) {
      timeLabels.push({
        firstDay: 'Lunch Break',
        openingTime,
        closingTime,
      });
    } else if (lastDay && lastDay.openingTime === openingTime && lastDay.closingTime === closingTime) {
      timeLabels[timeLabels.length - 1].lastDay = day;
    } else {
      timeLabels.push({
        firstDay: day,
        openingTime,
        closingTime,
      });
    }
  });

  return timeLabels;
};

export default getOpeningTimes;
