import { Accreditation } from '@propertypal/shared/src/types/agents';
import React, { FunctionComponent } from 'react';
import { closeTooltip, openTooltip } from '../notifications/Tooltip';
import { AgentAccreditationImage, AgentBox, Container, Overlay, OverlayText } from './AgentAccreditations.style';

interface Props {
  accreditations?: Accreditation[];
  missingAccreditations?: Accreditation[];
  center?: boolean;
}

const AgentAccreditations: FunctionComponent<Props> = (props) => {
  if (!props.accreditations?.length && !props.missingAccreditations?.length) {
    return null;
  }

  return (
    <Container center={props.center}>
      {props.accreditations &&
        props.accreditations.map((accreditation) => (
          <AgentBox
            key={accreditation.label}
            onMouseEnter={(e) => openTooltip(accreditation.tooltip, e)}
            onMouseLeave={() => closeTooltip()}
          >
            <AgentAccreditationImage
              src={accreditation.imageUrl}
              alt={`Logo for ${accreditation.label}`}
              loading="lazy"
            />
          </AgentBox>
        ))}

      {props.missingAccreditations &&
        props.missingAccreditations.map((accreditation) => (
          <AgentBox
            key={accreditation.label}
            onMouseEnter={(e) => openTooltip(accreditation.tooltip, e)}
            onMouseLeave={() => closeTooltip()}
          >
            <Overlay>
              <OverlayText>Not Provided</OverlayText>
            </Overlay>
            <AgentAccreditationImage
              src={accreditation.imageUrl}
              alt={`Logo for ${accreditation.label}`}
              loading="lazy"
            />
          </AgentBox>
        ))}
    </Container>
  );
};

export default AgentAccreditations;
