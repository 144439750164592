import { Property } from '@propertypal/shared/src/types/property';
import Button from '@propertypal/web-ui/src/buttons/Button';
import { SmallHeading, Text, TextLink } from '@propertypal/web-ui/src/typography';
import router from 'next/router';
import React, { FunctionComponent } from 'react';
import { Container, Heading, MobileBox, MobileTable, Table, TextBox } from './PropertyUnits.style';

interface Props {
  property: Property;
  hasSitemap: boolean;
  onSiteMapClick: () => void;
}

const PropertyUnits: FunctionComponent<Props> = (props) => {
  const isFeaturedDevelopment = !!props.property.featuredDevelopmentContent;

  if (!props.property.units || !props.property.development) {
    return null;
  }

  const developmentUrl = props.property.development.path;

  return (
    <Container className="pp-property-units pp-property-units-section" isFeaturedDevelopment={isFeaturedDevelopment}>
      <Heading className="pp-property-units-heading">
        <TextBox>
          <SmallHeading fontSize={20} fontWeight="600" mb={15}>
            {props.property.development.name} Development
          </SmallHeading>

          <Text className="hide-print">
            This property forms part of the <b>{props.property.development.name}</b> development. <br />
            <TextLink href={developmentUrl} prefetch={false}>
              Click to view
            </TextLink>{' '}
            all available property type within this development.
          </Text>
        </TextBox>

        <Button className="hide-print" containerStyle={{ maxWidth: 260 }} onClick={() => router.push(developmentUrl)}>
          View the Development
        </Button>
      </Heading>

      {!!props.property.units.length && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Unit Name</th>
                <th>Price</th>
                <th>Size</th>
                {props.hasSitemap && <th data-testid="sitemap-header">Site Map</th>}
              </tr>
            </thead>

            <tbody>
              {props.property.units.map((unit) => (
                <tr key={unit.name}>
                  <td>{unit.name}</td>
                  <td>
                    <b>{unit.price}</b>
                  </td>
                  <td>{unit.size}</td>
                  {props.hasSitemap && (
                    <td>
                      <Button onClick={props.onSiteMapClick} testID="sitemap-btn">
                        View Site Map
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>

          <MobileTable>
            {props.property.units.map((unit) => (
              <MobileBox key={unit.name}>
                <h3>{unit.name}</h3>
                <p>
                  <b>Price:</b> {unit.price}
                </p>
                <p>
                  <b>Size:</b> {unit.size}
                </p>

                {props.hasSitemap && (
                  <Button
                    onClick={props.onSiteMapClick}
                    testID="sitemap-btn"
                    containerStyle={{ marginTop: 15, maxWidth: 300, maxHeight: 50 }}
                  >
                    View Site Map
                  </Button>
                )}
              </MobileBox>
            ))}
          </MobileTable>
        </>
      )}
    </Container>
  );
};

export default PropertyUnits;
