import { Broadband } from '../../types/property';

const formatSpeed = (speed: string | null) => {
  if (speed) return `${speed} Mbps`;

  return `N/A`;
};

const formatBroadbandData = (broadband: Broadband) => {
  return [
    {
      label: 'Standard',
      download: formatSpeed(broadband.maxBbPredictedDown),
      upload: formatSpeed(broadband.maxBbPredictedUp),
      availability: !!broadband.maxBbPredictedDown,
    },
    {
      label: 'Superfast',
      download: formatSpeed(broadband.maxSfbbPredictedDown),
      upload: formatSpeed(broadband.maxSfbbPredictedUp),
      availability: !!broadband.maxSfbbPredictedDown,
    },
    {
      label: 'Ultrafast',
      download: formatSpeed(broadband.maxUfbbPredictedDown),
      upload: formatSpeed(broadband.maxUfbbPredictedUp),
      availability: !!broadband.maxUfbbPredictedDown,
    },
  ];
};

export default formatBroadbandData;
