const BROADBAND_INFO: { [key: string]: string } = {
  Standard:
    'Standard broadband refers to copper-line broadband, often referred to as ADSL (Asynchronous Digital Subscriber ' +
    'Line), is a type of internet connection that uses the copper wiring of your existing telephone network. Speeds ' +
    'for this kind of broadband range from 1-30Mbps.',
  Superfast:
    'Superfast broadband refers to a much faster variant of broadband, typically delivered through fibre-optic ' +
    "cables. It's often denoted as FTTC (Fibre to the Cabinet), where fibre-optic cables run to your local " +
    'street cabinet and the connection from the cabinet to your home is made via traditional copper telephone ' +
    'lines. Speeds for this kind of broadband typically range from 30-100Mbps.',
  Ultrafast:
    'Ultrafast broadband refers to the next step up in broadband speed, typically provided through full fibre or ' +
    "cable connections. It's often referred to as FTTP (Fibre to the Premises), which means that fibre-optic " +
    'cables run directly to your home, bypassing any copper wiring. Speeds for this kind of broadband typically ' +
    'start from 100Mbps and can reach up to 1Gbps (1000Mbps).',
};

export default BROADBAND_INFO;
