import { Property } from '@propertypal/shared/src/types/property';
import hasFeature from '@propertypal/shared/src/utils/featureFlag';
import PropertyNotFound from '@propertypal/web-ui/src/property/PropertyNotFound';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropertyDetails from '../components/property/PropertyDetails';
import useGpt from '../hooks/useGptSlot';
import { setTargeting } from '../services/ads/googletag';
import getProperty from '../services/property';
import { requireAuthToken } from '../utils/auth';

interface Props {
  published: boolean;
  property: Property;
  loggedIn: boolean;
  areaPriceTrackerV2: boolean;
}

export const getServerSideProps = requireAuthToken(async (ctx, store) => {
  const id = ctx.query.id as string;
  const address = ctx.query.address as string;
  const [result, published] = await getProperty(id, store.dispatch, ctx);

  if (result.data.path && (!address || `/${address}/${id}` !== result.data.path)) {
    const params = ctx.resolvedUrl && ctx.resolvedUrl.split('?')[1];
    const paramsStr = params ? `?${params}` : '';

    return {
      redirect: {
        destination: `${result.data.path}${paramsStr}`,
        permanent: true,
      },
    };
  }

  ctx.res.statusCode = published ? 200 : 404;

  return {
    props: {
      published,
      property: result.data,
      areaPriceTrackerV2: hasFeature(result.headers, 'sc-27458-pdp-price-tracker'),
    },
  };
});

const PropertyPage: FunctionComponent<Props> = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [property, setProperty] = useState<Property>(props.property);

  const updateProperty = async () => {
    if (typeof router.query.id === 'string') {
      const [result] = await getProperty(router.query.id, dispatch);

      setProperty(result.data);
    }
  };

  useEffect(() => {
    // next.js has bug where getServersideProps will not always be called
    // https://github.com/vercel/next.js/issues/34365
    // The following logic checks for a bad route and manually re-fetches the property details
    // checking props.property.pathId detects if getServersideProps has not run
    // checking property.pathId ensures this is not repeatedly called when navigating through gallery
    if (!router.asPath.includes(props.property.pathId) && !router.asPath.includes(property.pathId)) {
      updateProperty();
    }
  }, [router.asPath]);

  useEffect(() => {
    setProperty(props.property);
  }, [props.property]);

  useGpt(() => {
    setTargeting({
      Category: property?.propertyType?.key,
      SaleType: property?.saleType?.key,
      Postcode: [property?.postcode],
      EPCActualBand: property?.epc?.ratingShorthand?.split('/')[0]?.substring(0, 1),
      EPCPotentialBand: property?.epc?.ratingShorthand?.split('/')[1]?.substring(0, 1),
      BER: property?.ber?.alphanumericRating,
    });
  }, [property?.id]);

  if (!props.published) {
    return <PropertyNotFound property={property} loggedIn={props.loggedIn} />;
  }

  return (
    <PropertyDetails
      key={property.id}
      property={property}
      loggedIn={props.loggedIn}
      areaPriceTrackerV2={props.areaPriceTrackerV2}
    />
  );
};

export default React.memo(PropertyPage);
