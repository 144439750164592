import styled from 'styled-components';

export const Button = styled.button`
  font-size: 16px;
  color: ${(props) => props.theme.primary};
  transition: 300ms all;
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.backgroundMid};
  }
`;

export default Button;
