import useTouchDevice, { detectTouchDevice } from '@propertypal/web-app/src/hooks/useTouchDevice';
import React, { useState, CSSProperties } from 'react';
import { useTheme } from 'styled-components';
import useImagesLoaded from '../image/useImagesLoaded';

interface MagnifierProps {
  src: string;
  alt: string;
  width: number;
  id?: string;
  magnifierHeight?: number;
  magnifierWidth?: number;
  zoomLevel?: number;
  style?: CSSProperties;
}

const Magnifier: React.FC<MagnifierProps> = ({
  src,
  alt,
  width,
  id,
  magnifierHeight = 220,
  magnifierWidth = 220,
  zoomLevel = 1.5,
  style,
}) => {
  const imgsLoaded = useImagesLoaded([src]);
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const touchDevice = useTouchDevice();
  const theme = useTheme();

  const onMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent> | React.TouchEvent<HTMLImageElement>) => {
    // update cursor position
    const elem = e.currentTarget;
    const { top, left } = elem.getBoundingClientRect();
    // calculate cursor position on the image
    let cursorX: number | undefined;
    let cursorY: number | undefined;
    // for onTouchMove
    if ('touches' in e) {
      cursorX = e.touches[0].clientX - left - window.scrollX;
      cursorY = e.touches[0].clientY - top - window.scrollY;
    }
    // Sometimes a touch device, `onMouseMove` prop will trigger, causing to use `e.pageX/Y` which are incorrect values
    // on touch devices, so need to make sure that these values only get applied on non touch devices
    else if (!detectTouchDevice()) {
      cursorX = e.pageX - left - window.scrollX;
      cursorY = e.pageY - top - window.scrollY;
    }
    // ensure there are x & y values before showing the magnifier, otherwise it'll end up to the top left corner of img
    if (cursorX && cursorY) {
      setShowMagnifier(true);
      setXY([cursorX, cursorY]);
    } else setShowMagnifier(false);
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLImageElement, MouseEvent> | React.TouchEvent<HTMLImageElement>) => {
    // update image size
    const elem = e.currentTarget;
    const { width: imageWidth, height } = elem.getBoundingClientRect();
    setSize([imageWidth, height]);
    // Calculate cursor initial cursor position & whether to show the magnifier
    onMouseMove(e);
  };

  // close magnifier
  const onMouseLeave = () => setShowMagnifier(false);

  // There is times when by the time that an event listener is called, the image hasn't loaded
  // so there will be on background image on the magnifier
  if (!imgsLoaded) return null;

  return (
    <div
      style={{
        position: 'relative',
        width: `${width}px`,
        ...style,
      }}
    >
      <img
        data-testid="magnifier-image"
        src={src}
        alt={alt}
        id={id}
        style={{ width: `${width}px`, cursor: 'none' }}
        onMouseEnter={onMouseEnter}
        onTouchStart={onMouseEnter}
        onMouseMove={onMouseMove}
        onTouchMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onTouchEnd={onMouseLeave}
      />

      <div
        data-testid="magnifier"
        style={{
          display: showMagnifier ? '' : 'none',
          position: 'absolute',

          // prevent magnifier blocks the mousemove event of img
          pointerEvents: 'none',
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifierWidth / 2}px`,
          opacity: '1', // reduce opacity so you can verify position
          border: `10px solid ${theme.white}`,
          outline: `4px solid ${theme.textDark}`,
          borderRadius: '50%',
          backgroundImage: `url('${src}')`,
          backgroundRepeat: 'no-repeat',

          // calculate zoomed image size
          // double zoom level on touch devices
          backgroundSize: `${imgWidth * (touchDevice ? zoomLevel * 2 : zoomLevel)}px ${
            imgHeight * (touchDevice ? zoomLevel * 2 : zoomLevel)
          }px`,

          // calculate position of zoomed image.
          // double zoom level on touch devices
          backgroundPositionX: `${-x * (touchDevice ? zoomLevel * 2 : zoomLevel) + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * (touchDevice ? zoomLevel * 2 : zoomLevel) + magnifierHeight / 2}px`,
        }}
      />
    </div>
  );
};

export default Magnifier;
