import { Property } from '@propertypal/shared/src/types/property';
import generateMetaTags from '@propertypal/web-app/src/components/layout/MetaTags';
import Head from 'next/head';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import Button from '../buttons/Button';
import { Heading, LinkText, Text } from '../typography';
import { Container } from './PropertyNotFound.style';
import RelatedLinks from './RelatedLinks';

interface Props {
  property: Property;
  loggedIn: boolean;
}

const getMoreLink = (property: Property) => {
  if (!property.postcode) return '';

  return `/search?st=${property.saleType.key}&pt=${property.propertyType?.key}&q=${encodeURIComponent(
    property.postcode,
  )}`;
};

const PropertyNotFound: FunctionComponent<Props> = (props) => {
  const moreLink = getMoreLink(props.property);
  const saveSearchLink = props.loggedIn
    ? `${moreLink}#saveSearch`
    : `/login?sourcePath=${encodeURIComponent(`${moreLink}#saveSearch`)}`;

  return (
    <>
      <Head>
        {generateMetaTags({
          title: props.property.displayAddress,
          url: props.property.path,
        })}
      </Head>

      <Container>
        <Heading align="center">
          We&apos;re sorry, we can&apos;t find the {props.property.type || 'property'} you&apos;re looking for.
        </Heading>

        <Text align="center" mt={30}>
          This might be because the listing for <b>{props.property.displayAddress}</b> has been removed from the market
          or you have used an incorrect link.
        </Text>

        {!!moreLink && (
          <>
            <Text align="center" mt={30}>
              You can still{' '}
              <Link href={moreLink}>
                <LinkText as="span">search for other properties in the same area</LinkText>
              </Link>{' '}
              and we recommend that you <LinkText href={saveSearchLink}>save the search</LinkText> so you will be
              notified whenever a property is added, amended or removed from this search.
            </Text>

            <Link href={moreLink}>
              <Button containerStyle={{ margin: '30px auto 0', maxWidth: 360 }}>
                Search for more properties in this area
              </Button>
            </Link>
          </>
        )}
      </Container>

      {props.property.relatedSearches && <RelatedLinks title="USEFUL LINKS:" property={props.property} />}
    </>
  );
};

export default PropertyNotFound;
