import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 30px 0;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${(props) => props.theme.primary};
  font-weight: normal;
  flex: 1;

  @media (${mobileXXLargeMax}) {
    font-size: 20px;
  }
`;

export const StyledTable = styled.table`
  background-color: ${(props) => props.theme.backgroundLighter};
  border-top: 1px solid ${(props) => props.theme.keyline};
  border-left: 1px solid ${(props) => props.theme.keyline};
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0;

  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    border-bottom: 1px solid ${(props) => props.theme.keyline};
    border-right: 1px solid ${(props) => props.theme.keyline};
  }

  th:first-child {
    padding: 5px 20px;
    text-align: left;
  }

  td {
    background-color: ${(props) => props.theme.background};
    border-bottom: 1px solid ${(props) => props.theme.keyline};
    border-right: 1px solid ${(props) => props.theme.keyline};
    text-align: center;
    padding: 26px 0;
    color: ${(props) => props.theme.textDark};
  }

  td:first-child {
    background-color: ${(props) => props.theme.backgroundLighter};
    font-weight: bold;
    text-align: left;
    padding: 26px 20px;
    min-width: 112px;
  }

  @media (${mobileXXLargeMax}) {
    width: calc(100% + 30px);
    margin: 15px -15px;

    th {
      font-size: 10px;
    }

    th:first-child {
      padding: 5px 10px;
    }

    td {
      font-size: 14px;
    }

    td:first-child {
      padding: 26px 10px;
    }
  }
`;
