import { GOV_UK_URL, REVENUE_IE_URL } from '@propertypal/shared/src/constants/externalLinks';
import axios, { API_URL } from '@propertypal/shared/src/services/axios';
import { Property } from '@propertypal/shared/src/types/property';
import React, { FunctionComponent } from 'react';
import { Container, LinkText, Title } from './RelatedLinks.style';

interface Props {
  title?: string;
  property: Property;
}

const RelatedLinks: FunctionComponent<Props> = (props) => {
  const hasStampDutyComplex = props.property.keyInfo?.some((info) => info.key === 'STAMP_DUTY_COMPLEX');
  const hasRatesInfo = props.property.keyInfo?.some((info) => info.key === 'RATES');
  const hasStampDutySimple = props.property.keyInfo?.some((info) => info.key === 'STAMP_DUTY_SIMPLE');
  const hasBroadband = props.property.keyInfo?.some((info) => info.key === 'BROADBAND');
  const hasYearBuilt = props.property.keyInfo?.some((info) => info.key === 'YEAR_BUILT');

  const trackAgentLandingClick = async () => {
    if (props.property.account) {
      await axios(
        {
          method: 'POST',
          url: `${API_URL}/event`,
          data: {
            eventType: 'AGENT_LANDING_CLICK',
            property: props.property.id,
            accountNumber: props.property.account.accountNumber,
          },
        },
        undefined,
      );
    }
  };

  return (
    <Container className="hide-print">
      {props.title && <Title>{props.title}</Title>}

      {props.property.relatedSearches &&
        Object.keys(props.property.relatedSearches).map((key, index, keys) => (
          <span key={key}>
            <LinkText
              /* @ts-ignore */
              href={props.property.relatedSearches[key]}
              prefetch={false}
              onClick={() => index + 1 === keys.length && trackAgentLandingClick()}
            >
              {key}
            </LinkText>
            {index + 1 < keys.length ? ' | ' : ' '}
          </span>
        ))}

      {hasRatesInfo && (
        <span data-testid="rates-information">
          *¹ Rates information is provided for guidance purposes only, as the information source is subject to change.
          Please check the LPSNI website for further detail.{' '}
        </span>
      )}

      {hasStampDutySimple && (
        <span data-testid="stamp-duty-roi">
          *² Stamp Duty information is provided for guidance purposes only. Please check the Office of the Revenue
          Commissioners website for further details. <LinkText href={REVENUE_IE_URL}>({REVENUE_IE_URL})</LinkText>{' '}
        </span>
      )}

      {hasStampDutyComplex && (
        <span>
          *² Stamp Duty Land Tax information is provided for guidance purposes only. Please check the UK Government
          website for further details <LinkText href={GOV_UK_URL}>(www.gov.uk)</LinkText>. Higher amount applies when
          purchasing as buy to let or as an additional property.{' '}
        </span>
      )}

      {hasBroadband && (
        <span>
          *³ The speeds indicated on our checker represent the maximum estimated fixed-line speeds as predicted by the
          network operators providing services in a particular area. Please note that these are estimates, and actual
          service availability and speeds received at a given property may differ. Besides fixed-line services, options
          for 4G, 5G, or satellite broadband may be available at the address, contingent upon the necessary hardware.
          These alternatives are not reflected in the checker&apos;s estimates.
        </span>
      )}

      {hasYearBuilt && (
        <span>*⁴ Year Built, where provided, this information has been sourced from Land and Property services.</span>
      )}
    </Container>
  );
};

export default React.memo(RelatedLinks);
