import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div<{ center?: boolean }>`
  padding: 30px 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${(props) =>
    props.center &&
    `
    justify-content: center;
  `}
`;

export const AgentAccreditationImage = styled.img`
  max-height: 50px;
  width: auto;
  display: block;

  @media (${mobileXXLargeMax}) {
    max-height: 30px;
  }
`;

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  bottom: 50px;
  width: 120px;
  padding: 8px;
  background-color: rgba(15, 15, 15, 0.75);
  color: white;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  z-index: 2;
`;

export const AgentBox = styled.div`
  position: relative;
  margin-right: 20px;
  margin-bottom: 15px;

  &:hover ${Tooltip} {
    display: block;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OverlayText = styled.p`
  color: ${(props) => props.theme.textDark};
  font-size: 10px;
  text-align: center;
  font-weight: 600;
`;
