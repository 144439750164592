import { ROI_SCHOOL_TYPES, SCHOOL_TYPES } from '../constants/schoolTypes';
import { MarkerType } from '../types/map';
import { Property } from '../types/property';
import { School, SchoolsResult } from '../types/schools';

export const MILES_METERS = 1609.34;
export const KM_METERS = 1000;
export const EQ_RADIUS = 0.0000156785;

const ALL_SCHOOL_TYPES = [...SCHOOL_TYPES, ...ROI_SCHOOL_TYPES];

const ROI_GROUPED_TYPES = [
  { isGroup: true, label: 'Republic of Ireland Schools', value: 'roiSchools' },
  ...ROI_SCHOOL_TYPES,
  { isGroup: true, label: 'Northern Ireland Schools', value: 'niSchools' },
  ...SCHOOL_TYPES,
];

const NI_GROUPED_TYPES = [
  { isGroup: true, label: 'Northern Ireland Schools', value: 'niSchools' },
  ...SCHOOL_TYPES,
  { isGroup: true, label: 'Republic of Ireland Schools', value: 'roiSchools' },
  ...ROI_SCHOOL_TYPES,
];

export const getCurrentSchoolYear = (schools?: School[]) => {
  if (!schools || !schools[0]) return '';

  const fullYear = schools[0].years[0].year;

  return fullYear.substring(2);
};

export const getSchoolTypes = (isROI: boolean, schoolsResult?: SchoolsResult) => {
  if (schoolsResult?.schoolTypes?.length) {
    if (schoolsResult.schoolTypes.length > 4) {
      if (isROI) {
        return ROI_GROUPED_TYPES;
      }

      return NI_GROUPED_TYPES;
    }

    if (schoolsResult.schoolTypes.length > 2) return SCHOOL_TYPES;

    return ROI_SCHOOL_TYPES;
  }

  if (isROI) {
    return ROI_SCHOOL_TYPES;
  }

  return SCHOOL_TYPES;
};

export const getUrl = (property: Property, type: string, radius: string | null) => {
  let url = property.type === 'property' ? '/property' : '/development';
  url += `/${property.id}/schools?schoolType=${type}`;

  if (radius === null) {
    url += `&autoRadius=true&minResults=6`;
  } else {
    url += `&radius=${radius}`;
  }

  return url;
};

export const shouldDisplaySchoolData = (property: Property) => {
  return !!(
    property.type === 'property' &&
    property.propertyType?.key === 'residential' &&
    property.price?.priceType !== 'PUBLIC_AUCTIONS' &&
    property.published
  );
};

export const shouldDisplayLabels = (result: SchoolsResult | undefined, isROI: boolean) => {
  if (!result) {
    return [!isROI, isROI];
  }

  const showNILabels = !!result.schoolTypes.find((s) => s.key === 'grammar');
  const showROILabels = !!result.schoolTypes.find((s) => s.key === 'roiprimary');

  return [showNILabels, showROILabels];
};

export const getSchoolMarkers = (result: SchoolsResult | undefined, checkedSchools: string[]): MarkerType[] => {
  const markers: MarkerType[] = [];

  if (result) {
    Object.keys(result.results).forEach((schoolType) => {
      const type = ALL_SCHOOL_TYPES.find((mType) => mType.value === schoolType);

      if (checkedSchools.includes(schoolType)) {
        result.results[schoolType].forEach((school) => {
          markers.push({
            id: school.id,
            lat: school.searchTerm.coordinate.latitude,
            lng: school.searchTerm.coordinate.longitude,
            icon: type?.icon,
            type: type?.value,
          });
        });
      }
    });
  }

  return markers;
};

export const getAllSchools = (result: SchoolsResult | undefined) => {
  const schools: School[] = [];

  if (result) {
    Object.keys(result.results).forEach((schoolType) => {
      schools.push(...result.results[schoolType]);
    });
  }

  return schools;
};

export default getCurrentSchoolYear;
