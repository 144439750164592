import { laptopMediumMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { MAX_WIDTH } from '../layout/PageWrapper.style';
import { SubHeading } from '../typography';

const registerInterestMax = 'max-width: 1200px';

export const Container = styled.div<{ registerInterest?: boolean }>`
  margin: 60px auto;
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  padding: 0 30px;

  @media (${({ registerInterest }) => (registerInterest ? registerInterestMax : laptopMediumMax)}) {
    padding: 0 15px;

    ${SubHeading} {
      display: none;
    }
  }
`;

export const Table = styled.div`
  background: ${(props) => props.theme.background};
  width: 100%;
`;

export const Column = styled.div<{ column?: boolean | null }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.keyline};
  border-left: 1px solid ${(props) => props.theme.keyline};
  align-items: center;

  ${({ column }) =>
    column
      ? `
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  `
      : ''}

  img {
    max-width: 140px;
    max-height: 46px;
    margin-right: 20px;
  }

  h4 {
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
  }

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 5px;
    }

    h4 {
      text-align: center;
    }
  }
`;

export const TableRow = styled.div<{ heading?: boolean; registerInterest?: boolean }>`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.heading &&
    `
    background-color: ${props.theme.backgroundLightest};
    
    @media (${props.registerInterest ? registerInterestMax : laptopMediumMax}) {
      ${Column}:nth-child(n + 2) {
        display: none;
      }
    }
  `}

  ${Column}:nth-child(n + 2) {
    min-width: 275px;
    max-width: 275px;
  }

  ${Column}:last-child {
    border-right: 1px solid ${(props) => props.theme.keyline};
  }

  &:last-child ${Column} {
    border-bottom: 1px solid ${(props) => props.theme.keyline};
  }

  @media (${({ registerInterest }) => (registerInterest ? registerInterestMax : laptopMediumMax)}) {
    flex-direction: column;

    ${Column} {
      width: 100%;
      max-width: none !important;
      border-right: 1px solid ${(props) => props.theme.keyline};
    }

    &:last-child ${Column} {
      border-bottom: none;
    }

    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.keyline};
    }
  }
`;

export const ShowPhoneNumber = styled.div`
  display: block;
  width: 100%;

  @media (${mobileXXLargeMax}) {
    display: none;
  }
`;

export const CallAgentBox = styled.div`
  display: none;
  width: 100%;

  @media (${mobileXXLargeMax}) {
    display: block;
  }
`;
