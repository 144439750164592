import axios, { API_URL } from '@propertypal/shared/src/services/axios';
import { GetServerSidePropsContext } from 'next/types';
import { Dispatch } from 'redux';
import { PROPERTY_IMAGE_LOCATOR } from '../components/property/PropertyDetails';

const getProperty = async (id: string, dispatch: Dispatch, ctx?: GetServerSidePropsContext) => {
  // check if permit and expiry query strings exists, coming from PDI, property history
  // these allow a user to view a property that has been archived or otherwise removed from site
  const permit = typeof ctx?.query.permit === 'string' ? ctx.query.permit : undefined;
  const expiry = typeof ctx?.query.expiry === 'string' ? ctx.query.expiry : undefined;
  const type = id.includes('d') ? 'development' : 'property';
  const pathId = type === 'development' ? Number(id.replace('d', '')) : Number(id) - 1200;

  let url = `${API_URL}/${type}/${pathId}?imageLocator=${encodeURIComponent(PROPERTY_IMAGE_LOCATOR.join(','))}`;

  // append permit and expiry query params to url if they exist
  if (permit && expiry) url += `&permit=${permit}&expiry=${expiry}`;

  try {
    const result = await axios(
      {
        method: 'GET',
        url,
      },
      dispatch,
      ctx,
    );

    return [result, true];
  } catch (err: any) {
    if (err.response && err.response.status === 404) {
      return [err, false];
    }

    throw err;
  }
};

export default getProperty;
