import { Property } from '@propertypal/shared/src/types/property';
import getSellingAgent, { getAgentColours } from '@propertypal/shared/src/utils/property/getSellingAgent';
import React, { FunctionComponent } from 'react';
import { Container, Logo } from './PropertyAgentBar.style';

interface Props {
  property: Property;
  hideOnMobile?: boolean;
}

const PropertyAgentBar: FunctionComponent<Props> = (props) => {
  const sellingAgent = getSellingAgent(props.property);
  const { pdpBrandingBgColour } = getAgentColours(sellingAgent);

  if (!pdpBrandingBgColour) {
    return null;
  }

  return (
    <Container color={pdpBrandingBgColour} hideOnMobile={props.hideOnMobile} className="hide-print">
      <Logo src={sellingAgent?.logo?.premier} />
    </Container>
  );
};

export default PropertyAgentBar;
