/* eslint-disable max-len */
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import config from '../../../../app.config.json';
import { selectUser } from '../reducers/user';
import axios, { API_URL } from '../services/axios';
import { MortgageErrorStatus, MortgageProductResult } from '../types/mortgages';
import { Broker, Property } from '../types/property';
import floatToCurrency, { parseNumber } from '../utils/floatToCurrency';
import {
  getDefaultDeposit,
  getDefaultTerm,
  getDepositPercentage,
} from '../utils/property/getMortgageCalculatorDefaults';
import useApi from './useApi';
import useDebounce from './useDebounce';

export interface MortgageCalculatorFormValues {
  paymentTerm: number;
  propertyValue?: number;
  deposit: number;
}

export interface MortgageEvent {
  value: number;
  deposit: number;
  term: number;
  fieldModified?: string;
  depositFromCookie: boolean;
  termFromCookie: boolean;
}

export interface MortgageCalcData {
  data?: MortgageProductResult;
  error: any;
  loading: boolean;
  sending: boolean;
  errorStatus?: MortgageErrorStatus;
  hasResult: boolean;
  mortgagePayment: string;
  depositPercentage?: number;
  hasMortgageCalculator: boolean;
  hasBrokerCalculator: boolean;
  getMortgageResult: (values: MortgageCalculatorFormValues) => void;
  logMortgageEvent: (event: MortgageEvent) => void;
  submitLead: (values: any) => Promise<boolean>;
  broker: Broker | null;
}

const COMMENT = 'Please confirm my Mortgage Eligibility';

const DEBOUNCE_TIME = process?.env?.JEST_WORKER_ID ? 0 : 1000;

const getError = (depositPercentage?: number, data?: MortgageProductResult): MortgageErrorStatus | undefined => {
  let eStatus: MortgageErrorStatus | undefined;

  if (data) {
    const { deposit, value, product } = data;

    if (deposit === value) {
      eStatus = { error: 'DEPOSIT_EQUAL_VALUE' };
    } else if (deposit > value) {
      eStatus = { error: 'DEPOSIT_GREATER_VALUE' };
    } else if (depositPercentage && depositPercentage > 99) {
      eStatus = { error: 'INVALID_LTV' };
    } else if (product == null) {
      eStatus = { error: 'NO_RESULTS' };
    }
  }

  return eStatus;
};

export const getBroker = (property: Property): Broker | null => {
  const { brokers, agents } = property;

  if (brokers) {
    // If any agent has no brokers, return none
    if (agents && brokers.length < agents.length) {
      return null;
    }

    if (brokers.length === 1) {
      return brokers[0];
    }

    // If all brokers are the same, return the first one
    if (brokers.length > 1) {
      const firstBroker = brokers[0].name;
      const allBrokers = brokers.filter((b) => b.name === firstBroker);

      if (allBrokers.length === brokers.length) {
        return brokers[0];
      }
    }
  }

  return null;
};

const postBrokerLead = async (propertyId: number, brokerId: number, dispatch: Dispatch, data: any) => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/property/${propertyId}/lead/broker/${brokerId}`,
        data,
      },
      dispatch,
    );

    return true;
  } catch {
    return false;
  }
};

const useMortgageCalculator = (property: Property): MortgageCalcData => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const noOfInteractions = useRef(0);
  const [data, error, loading, fetchProduct] = useApi<MortgageProductResult>('', false);
  const [sending, setSending] = useState(false);
  const mortgagePayment =
    data?.product && data.product.initialMonthlyPayment > 0
      ? floatToCurrency(data?.product?.initialMonthlyPayment)
      : '-';
  const depositPercentage = data ? getDepositPercentage(data.deposit, data.value) : undefined;
  const errorStatus = getError(depositPercentage, data);
  const hasResult = !errorStatus && !!data;
  const broker = getBroker(property);
  const hasBrokerCalculator = !!(property.brokers && property.brokers.length > 0 && property.published);
  const hasMortgageCalculator =
    property.type === 'property' &&
    property.propertyType?.key === 'residential' &&
    property.saleType?.key === 'sale' &&
    property.countryCode === 'GBR' &&
    property.price !== undefined &&
    (typeof property.price.price === 'number' || typeof property.price.minPrice === 'number') &&
    property.price.priceType !== 'PUBLIC_AUCTIONS' &&
    property.status?.key !== 'sold' &&
    property.status?.key !== 'saleAgreed' &&
    !!property.published;

  const debounceFetchResult = useDebounce(
    fetchProduct,
    DEBOUNCE_TIME,
    {
      leading: false,
      trailing: true,
    },
    [],
  );

  const getMortgageResult: MortgageCalcData['getMortgageResult'] = (values) => {
    let url = `/mortgages/mortgage/product?value=${values.propertyValue}`;
    url += `&term=${values.paymentTerm}&defaultTerm=${getDefaultTerm(property)}`;
    url += `&deposit=${values.deposit}&defaultDeposit=${getDefaultDeposit(property)}`;

    if (property.development) {
      url += '&newHome=true';
    }

    if (user.profile?.currentPassportId) {
      url += `&passportId=${user.profile?.currentPassportId}`;
    }

    debounceFetchResult(url);
  };

  const submitLead: MortgageCalcData['submitLead'] = async (values) => {
    let res = false;
    setSending(true);

    const mValues = {
      ...values,
      propertyValue: parseNumber(values.propertyValue),
      deposit: parseNumber(values.deposit),
      paymentTerm: parseNumber(values.paymentTerm),
    };

    if (broker?.asssociatedAgents?.length) {
      const results = [];
      for (let i = 0; i < broker.asssociatedAgents.length; i += 1) {
        const mData = {
          ...mValues,
          interestRate: data?.product?.initialRate,
          contact: broker.asssociatedAgents[i],
          comment: COMMENT,
        };
        mData.contact = broker.asssociatedAgents[i];

        results.push(postBrokerLead(property.id, broker.id, dispatch, mData));
      }

      res = (await Promise.all(results))[0];
    } else if (broker) {
      const mData = {
        ...mValues,
        interestRate: data?.product?.initialRate,
        contact: property.account.accountNumber,
        comment: COMMENT,
      };

      res = await postBrokerLead(property.id, broker.id, dispatch, mData);
    }

    setSending(false);
    return res;
  };

  const logMortgageEvent = async (payload: MortgageEvent) => {
    noOfInteractions.current += 1;

    try {
      await axios(
        {
          method: 'POST',
          url: `${config.apiUrl}/event`,
          data: {
            property: property.id,
            eventType: 'MORTGAGE_CALCULATOR_INTERACTION',
            mortgageCalculator: {
              sequence: noOfInteractions.current,
              outcome: data?.product ? 'Result' : 'Error',
              ...payload,
              deposit: parseNumber(payload.deposit),
              term: parseNumber(payload.term),
            },
          },
        },
        undefined,
      );
    } catch {
      // silent error
    }
  };

  return {
    data,
    error,
    loading,
    getMortgageResult,
    mortgagePayment,
    depositPercentage,
    errorStatus,
    hasResult,
    hasMortgageCalculator,
    hasBrokerCalculator,
    logMortgageEvent,
    submitLead,
    sending,
    broker,
  };
};

export default useMortgageCalculator;
