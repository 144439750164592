import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faMoneyBill } from '@fortawesome/pro-regular-svg-icons/faMoneyBill';
import { keyInfoTooltips } from '@propertypal/shared/src/constants/keyInfo';
import useInView from '@propertypal/shared/src/hooks/useInView';
import { MortgageCalcData } from '@propertypal/shared/src/hooks/useMortgageCalculator';
import CoOwnershipLogo from '@propertypal/shared/src/resources/logos/co-ownership-logo.svg?url';
import { getItem } from '@propertypal/shared/src/services/local.storage';
import { GREY_300, TOMATO_RED } from '@propertypal/shared/src/styles/theme';
import { Property, KeyInfo } from '@propertypal/shared/src/types/property';
import capitalize from '@propertypal/shared/src/utils/capitalize';
import floatToCurrency, { formatNumber, parseNumber } from '@propertypal/shared/src/utils/floatToCurrency';
import {
  getDefaultDeposit,
  getDefaultTerm,
} from '@propertypal/shared/src/utils/property/getMortgageCalculatorDefaults';
import {
  groupFinancialInfo,
  groupOverview,
  groupDetailedFeatures,
  ICON_MAP,
  STAMP_DUTY_TEXT_MAP,
  groupViewInfo,
  getStyleIcon,
  groupAuctionDetails,
} from '@propertypal/shared/src/utils/property/groupKeyInfo';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import React, { FunctionComponent, useRef } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';
import { openTooltip } from '../notifications/Tooltip';
import InfoIcon from '../schools/InfoIcon';
import { TextLink } from '../typography';
import EnergyRatingIcon from './EnergyRatingIcon';
import {
  BottomRow,
  Content,
  KeyInfoGrid,
  KeyInfoItem,
  KeyInfoLoader,
  KeyInfoText,
  KeyInfoTitle,
  SubText,
  Title,
} from './KeyInfoList.style';

interface Props {
  buyingStatus: string | null;
  property: Property;
  onClick: (info: KeyInfo) => void;
  mortgageCalculator: MortgageCalcData;
}

const MORTGAGE_INFO: KeyInfo = {
  key: 'TYPICAL_MORTGAGE',
  name: 'Mortgage',
  text: '',
  textHtml: '',
  group: '',
};

const ICON_STYLE = {
  fontSize: 20,
  width: 25,
  minWidth: 25,
  marginTop: 2,
};

const TitleInfoIcon: React.FC<{ txt: string }> = ({ txt }) => (
  <InfoIcon
    onMouseEnter={(e) => openTooltip(txt, e, TOMATO_RED, 14, 'pp-tooltip', 250)}
    icon={faInfoCircle}
    fontSize={16}
    testID="keyInfoIcon"
    className="pp-stamp-duty-text"
  />
);

const renderText = (info: KeyInfo, property: Property, onClick: Props['onClick'], buyingStatus: string | null) => {
  const tooltip = keyInfoTooltips?.[info.key];

  if (info.key === 'EPC_RATING' || info.key === 'EPC_FILES' || info.key === 'EPC_RATING_CHART') {
    return (
      <KeyInfoItem key={info.key}>
        <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />

        <div>
          <KeyInfoTitle>Energy Rating</KeyInfoTitle>
          <KeyInfoText>
            <button data-testid="epcText" onClick={() => onClick(info)} type="button" className="pp-epc-text">
              {info.text}
            </button>
          </KeyInfoText>
        </div>
      </KeyInfoItem>
    );
  }

  if (info.key === 'BER') {
    return (
      <KeyInfoItem key={info.key}>
        <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />
        <div>
          <KeyInfoTitle>Energy Rating</KeyInfoTitle>
          <EnergyRatingIcon property={property} />
        </div>
      </KeyInfoItem>
    );
  }

  if (info.key === 'STAMP_DUTY_COMPLEX' && info.buyerTypeCosts) {
    return (
      <KeyInfoItem key={info.key}>
        <FontAwesomeIcon icon={ICON_MAP[info.key]} style={ICON_STYLE} />

        {!!buyingStatus && (
          <div>
            <KeyInfoTitle hasTooltip>
              <span>Stamp Duty</span>
              <TitleInfoIcon txt={tooltip?.content} />
            </KeyInfoTitle>
            <KeyInfoText data-testid="stampDutyText">{info.buyerTypeCosts[buyingStatus]}*²</KeyInfoText>
            <SubText>
              (Based on being a {STAMP_DUTY_TEXT_MAP[buyingStatus]}){' '}
              <button onClick={() => onClick(info)} type="button" className="pp-stamp-duty-text">
                Change
              </button>
            </SubText>
          </div>
        )}

        {!buyingStatus && (
          <div>
            <KeyInfoTitle hasTooltip>
              <span>Stamp Duty</span>
              <TitleInfoIcon txt={tooltip?.content} />
            </KeyInfoTitle>
            <KeyInfoText>
              <button
                data-testid="stampDutyText"
                onClick={() => onClick(info)}
                type="button"
                className="pp-stamp-duty-text"
              >
                Calculate
              </button>
            </KeyInfoText>
          </div>
        )}
      </KeyInfoItem>
    );
  }

  if (info.key === 'STAMP_DUTY_SIMPLE') {
    return (
      <KeyInfoItem key={info.key} data-testid="stampDutySimple">
        <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />

        <div>
          <KeyInfoTitle>Stamp Duty</KeyInfoTitle>
          <KeyInfoText>{info.text}*²</KeyInfoText>
        </div>
      </KeyInfoItem>
    );
  }

  if (info.key === 'BROADBAND') {
    return (
      <KeyInfoItem key={info.key} data-testid="broadbandSpeed">
        <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />

        <div>
          <KeyInfoTitle>Broadband</KeyInfoTitle>
          <KeyInfoText>
            <button
              data-testid="broadbandSpeedButton"
              onClick={() => onClick(info)}
              type="button"
              className="pp-stamp-duty-text"
            >
              {property.ofcomBroadband?.maxUfbbPredictedDown
                ? 'Ultrafast'
                : property.ofcomBroadband?.maxSfbbPredictedDown
                ? 'Superfast'
                : 'Standard'}{' '}
              Broadband
            </button>
            *³
          </KeyInfoText>
        </div>
      </KeyInfoItem>
    );
  }

  if (info.key === 'STATUS') {
    return (
      <KeyInfoItem key={info.key}>
        <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />

        <div>
          <KeyInfoTitle>{info.name}</KeyInfoTitle>
          <KeyInfoText>
            {['Let agreed', 'Sale agreed', 'Sold', 'Let'].includes(info.text) ? (
              <b>{capitalize(info.text)}</b>
            ) : (
              capitalize(info.text)
            )}
          </KeyInfoText>
        </div>
      </KeyInfoItem>
    );
  }

  if (info.key === 'AUCTION_DATE') {
    return (
      <KeyInfoItem key={info.key}>
        <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />

        <div>
          <KeyInfoTitle>Auction Date</KeyInfoTitle>
          <KeyInfoText>{info.text === 'finished' ? <b>Finished</b> : info.text}</KeyInfoText>
        </div>
      </KeyInfoItem>
    );
  }

  if (info.key === 'STYLE' || info.key === 'LAND_TYPE') {
    return (
      <KeyInfoItem key={info.key}>
        <FontAwesomeIcon icon={getStyleIcon(info.text)} color={GREY_300} style={ICON_STYLE} />

        <div>
          <KeyInfoTitle>{info.name}</KeyInfoTitle>
          <KeyInfoText>{info.text}</KeyInfoText>
        </div>
      </KeyInfoItem>
    );
  }

  return (
    <KeyInfoItem key={info.key}>
      {info.key in ICON_MAP && <FontAwesomeIcon icon={ICON_MAP[info.key]} color={GREY_300} style={ICON_STYLE} />}
      <div>
        <KeyInfoTitle hasTooltip={!!tooltip}>
          <span>{info.name}</span>
          {tooltip && tooltip.type !== 'text' && <TitleInfoIcon txt={tooltip.content} />}
        </KeyInfoTitle>
        <KeyInfoText hasTooltip={!!tooltip}>
          <span>
            {info.text}
            {info.key === 'RATES' && <>*¹</>}
            {info.key === 'YEAR_BUILT' && <>*⁴</>}
          </span>
          {tooltip && tooltip.type === 'text' && <TitleInfoIcon txt={tooltip.content} />}
        </KeyInfoText>

        {info.key === 'PRICE' && property.includeFeeCharges && (
          <TextLink
            href={`/letting-agent-fees/${property.account.accountNumber}`}
            prefetch={false}
            data-testid="fees-link"
          >
            {' '}
            (some fees may apply)
          </TextLink>
        )}
      </div>
    </KeyInfoItem>
  );
};

const KeyInfoList: FunctionComponent<Props> = ({ property, mortgageCalculator, onClick, buyingStatus }) => {
  const isFeaturedDevelopment = !!property.featuredDevelopmentContent;
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const overview = groupOverview(property.keyInfo);
  const auctionDetails = groupAuctionDetails(property.keyInfo);
  const detailedFeatures = groupDetailedFeatures(property.keyInfo);
  const financialInfo = groupFinancialInfo(property.keyInfo);
  const viewInfo = groupViewInfo(property.keyInfo);
  const coOwnership = property.keyInfo?.find((info) => info.key === 'COOWNERSHIP');
  const { data, error, errorStatus, mortgagePayment, depositPercentage, hasMortgageCalculator, getMortgageResult } =
    mortgageCalculator;
  const showFinancial = financialInfo?.length || hasMortgageCalculator;

  const fetchMortgageResult = () => {
    if (!data && !error && hasMortgageCalculator) {
      const defaultTerm = getItem<string>('defaultTerm');
      const defaultDeposit = getItem<string>('defaultDeposit');

      getMortgageResult({
        propertyValue: property.price?.price,
        paymentTerm: defaultTerm ? parseNumber(defaultTerm) : getDefaultTerm(property),
        deposit: defaultDeposit ? parseNumber(defaultDeposit) : getDefaultDeposit(property),
      });
    }
  };

  useInView(containerRef, { threshold: 0.5, rootMargin: '0px 0px 600px' }, fetchMortgageResult);

  return (
    <>
      {!!auctionDetails?.length && (
        <Content className="pp-property-summary pp-key-info" isFeaturedDevelopment={isFeaturedDevelopment}>
          <Title>Auction Details</Title>

          <KeyInfoGrid>{auctionDetails?.map((info) => renderText(info, property, onClick, buyingStatus))}</KeyInfoGrid>
        </Content>
      )}

      <Content
        ref={containerRef}
        className="pp-property-summary pp-key-info"
        isFeaturedDevelopment={isFeaturedDevelopment}
      >
        <Title>Property Overview</Title>

        <KeyInfoGrid>{overview?.map((info) => renderText(info, property, onClick, buyingStatus))}</KeyInfoGrid>
      </Content>

      {!!detailedFeatures?.length && (
        <Content className="pp-property-summary pp-key-info" isFeaturedDevelopment={isFeaturedDevelopment}>
          <Title>Property Features</Title>

          <KeyInfoGrid>
            {detailedFeatures?.map((info) => renderText(info, property, onClick, buyingStatus))}
          </KeyInfoGrid>
        </Content>
      )}

      {showFinancial && (
        <Content className="pp-property-summary pp-key-info" isFeaturedDevelopment={isFeaturedDevelopment}>
          <Title>Property Financials</Title>

          <KeyInfoGrid data-testid="key-info-grid">
            {financialInfo?.map((info) => renderText(info, property, onClick, buyingStatus))}

            {hasMortgageCalculator && (
              <KeyInfoItem>
                <FontAwesomeIcon icon={faMoneyBill} color={theme.textDark} style={ICON_STYLE} />

                <div>
                  <KeyInfoTitle>Typical Mortgage</KeyInfoTitle>

                  {!data && !errorStatus && !error && (
                    <KeyInfoLoader>
                      <Loader color={theme.backgroundLight} size={28} />
                    </KeyInfoLoader>
                  )}

                  {(errorStatus || error) && (
                    <>
                      <KeyInfoText data-testid="no-mortgage-result">No mortgage results</KeyInfoText>

                      <SubText>
                        Try changing your{' '}
                        <button onClick={() => onClick(MORTGAGE_INFO)} type="button" className="pp-stamp-duty-text">
                          criteria
                        </button>{' '}
                        below
                      </SubText>
                    </>
                  )}

                  {!errorStatus && !!data && (
                    <>
                      <KeyInfoText data-testid="typical-mortgage-text">
                        <button
                          onClick={() => onClick(MORTGAGE_INFO)}
                          type="button"
                          className="pp-stamp-duty-text"
                        >{`${mortgagePayment} per month`}</button>
                      </KeyInfoText>

                      <SubText data-testid="typical-mortgage-subtext">
                        {`${floatToCurrency(data.deposit, {
                          precision: 0,
                        })} (${depositPercentage}%) deposit (${data.term} year term)`}
                      </SubText>
                    </>
                  )}
                </div>
              </KeyInfoItem>
            )}
          </KeyInfoGrid>
        </Content>
      )}

      {!!viewInfo?.length && (
        <Content className="pp-property-summary pp-key-info" isFeaturedDevelopment={isFeaturedDevelopment}>
          <Title>Property Engagement</Title>

          <KeyInfoGrid>
            {viewInfo.map((info) => (
              <KeyInfoItem key={info.name}>
                <FontAwesomeIcon icon={faEye} color={theme.textDark} style={ICON_STYLE} />

                <div>
                  <KeyInfoTitle>{info.name}</KeyInfoTitle>
                  <KeyInfoText>{formatNumber(info.text)}</KeyInfoText>
                </div>
              </KeyInfoItem>
            ))}
          </KeyInfoGrid>
        </Content>
      )}

      {coOwnership && (
        <BottomRow>
          <img src={CoOwnershipLogo.src} className="co-ownership-logo" alt="Co-Ownership" />
          <KeyInfoText dangerouslySetInnerHTML={{ __html: coOwnership.textHtml }} />
        </BottomRow>
      )}
    </>
  );
};

export default KeyInfoList;
