import { largeTabletMax, mobileXLargeMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 28px;
  color: ${(props) => props.theme.textDark};

  @media (${mobileXLargeMax}) {
    font-size: 24px;
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  grid-auto-flow: row dense;
  margin: 40px 0;

  @media (${largeTabletMax}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (${tabletMax}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (${mobileXLargeMax}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const DevelopmentBox = styled(Link)`
  position: relative;
  display: block;
  background-color: ${(props) => props.theme.background};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.backgroundLightest};
  }
`;

export const TextBox = styled.div`
  padding: 15px;

  h3 {
    font-size: 20px;
    color: ${(props) => props.theme.textDark};
  }

  p {
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
  }
`;
